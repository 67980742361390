'use strict';

const TAG_ORDER_MAPPING = {
  "in_my_network": 1,
  "jobPosition": 1,
  "jobPositionKeyword": 2,
  "sectorCategory": 3,
  "sectorIDCC": 4,
  "sectorNaf": 4,
  "sectorKeyword": 5,
  "decisionMakerNews": 6,
  "decisionMakerHierarchicalLevel": 7,
  "decisionMakerSchool": 8,
  "decisionMakerSchoolType": 9,
  "decisionMakerPreviousJob": 10,
  "decisionMakerHobbies": 11,
  "decisionMakerCommunication": 12,
  "companyProfileCriteria": 13,
  "companyProfileCriteriaSize": 13,
  "companyProfileCriteria2": 14,
  "localisations": 15,
  "localisationDepartment": 16,
  "localisationCity": 17,
  "localisationCountry": 18,
  "companyNews": 19,
  "companyProfileBrand": 20,
  "identifiers": 21,
  "companyList": 22,
  "personList": 23,
  "includeList": 24,
  "excludeList": 25,
  "includeCampaign": 26,
  "excludeCampaign": 27,
  "includeSequence": 28,
  "excludeSequence": 29,
  "group_head_country_list": 30,
  "group_head_country_tag_id_list": 30,
  "closureDate": 31,
  "cacStartDate": 32,
};

export {
  TAG_ORDER_MAPPING,
};
