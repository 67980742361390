export default {
  "fr": {
    "news_type_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision"])},
    "news_type_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomination"])},
    "news_type_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "news_type_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anniversaire"])},
    "news_type_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de fond"])},
    "news_type_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conférence"])},
    "news_type_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])},
    "news_type_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal d'affaire"])},
    "news_type_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
    "news_type_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Contact"])},
    "news_type_id_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision"])},
    "news_type_id_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomination"])},
    "news_type_id_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "news_type_id_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anniversaire"])},
    "news_type_id_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de fond"])},
    "news_type_id_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conférence"])},
    "news_type_id_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])},
    "news_type_id_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal d'affaire"])},
    "news_type_id_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
    "news_type_id_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Contact"])}
  },
  "en": {
    
  }
}