export default {
  "fr": {
    "job_type_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction R&D, innovation"])},
    "job_type_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction artistique, création, design"])},
    "job_type_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction commerciale"])},
    "job_type_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction communication, relations publiques"])},
    "job_type_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction de business unit"])},
    "job_type_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction achats, services généraux"])},
    "job_type_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction des ressources humaines"])},
    "job_type_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction des systèmes d'information "])},
    "job_type_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction développement durable, environnement, RSE"])},
    "job_type_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction distribution et réseau"])},
    "job_type_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction finance, administratif"])},
    "job_type_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction générale"])},
    "job_type_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction immobilière"])},
    "job_type_id_list_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction industrielle"])},
    "job_type_id_list_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction stratégie"])},
    "job_type_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction juridique, risk management"])},
    "job_type_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction logistique, supply chain"])},
    "job_type_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction marketing"])},
    "job_type_id_list_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction digitale"])},
    "job_type_id_list_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction technique, qualité"])},
    "job_type_id_list_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gouvernance non exécutive"])},
    "job_type_id_list_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres fonctions"])},
    "job_type_id_list_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métiers de la banque et de la finance"])},
    "job_type_id_list_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métiers de l'administration publique "])},
    "job_type_id_list_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métiers des médias et du journalisme"])},
    "job_type_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métiers du conseil"])},
    "job_type_id_list_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité de direction, exécutif"])},
    "job_type_id_list_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction technique, production, qualité"])},
    "job_type_id_list_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métiers du CSE"])}
  },
  "en": {
    
  }
}