'use strict';

const AI_CONSTANTS = {
  BLOCKED: {
    ID: 6,
    LABEL: 'blocked',
  },
  BOUNCE: {
    ID: 5,
    LABEL: 'bounce',
  },
  // Représente une erreur lors de la tentative de catégorisation (échec du prompt, erreur réseau, etc.)
  ERROR: {
    ID: 0,
    LABEL: 'erreur',
  },
  REPLIED: {
    ID: 7,
    LABEL: 'reply',
  },
  REPLY_AUTO: {
    ID: 3,
    LABEL: 'réponse automatique',
  },
  REPLY_INTERESTED: {
    ID: 1,
    LABEL: 'intéressé(e)',
  },
  REPLY_NOT_INTERESTED: {
    ID: 2,
    LABEL: 'pas intéressé(e)',
  },
  REPLY_OTHER: {
    ID: 4,
    LABEL: 'autre',
  },
};

export {
  AI_CONSTANTS,
};
