export default {
  "fr": {
    "regional_leaders_id_list_271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bretagne"])},
    "regional_leaders_id_list_275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auvergne Rhône-Alpes"])},
    "regional_leaders_id_list_281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Aquitaine"])},
    "regional_leaders_id_list_285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occitanie"])},
    "regional_leaders_id_list_286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauts de France"])},
    "regional_leaders_id_list_288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PACA et Corse"])},
    "regional_leaders_id_list_289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand Est"])},
    "regional_leaders_id_list_293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de la Loire"])},
    "regional_leaders_id_list_299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normandie"])},
    "regional_leaders_id_list_303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bourgogne-Franche-Comté"])},
    "regional_leaders_id_list_307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre Val-de-Loire"])},
    "regional_leaders_id_list_313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DROM"])}
  },
  "en": {
    
  }
}