export default {
  "fr": {
    "company_stock_market_index_id_list_143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAC 40 (sièges)"])},
    "company_stock_market_index_id_list_146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAC Small (siège)"])},
    "company_stock_market_index_id_list_155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises cotées éligibles PEA-PME (siège)"])},
    "company_stock_market_index_id_list_168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Euronext Growth (siège)"])},
    "company_stock_market_index_id_list_169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Biotech"])},
    "company_stock_market_index_id_list_201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SBF 120 (sièges)"])},
    "company_stock_market_index_id_list_224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SBF 120 (filiales)"])},
    "company_stock_market_index_id_list_228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAC 40 (filiales)"])},
    "company_stock_market_index_id_list_232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Euronext Family Business"])},
    "company_stock_market_index_id_list_329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enternext PEA PME 150"])}
  },
  "en": {
    
  }
}