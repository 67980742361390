<template>
  <div
    v-click-outside="close"
    class="select"
  >
    <div
      v-if="!unfold"
      class="select__main"
      :class="{'select__main--focus': show_options, 'disable': disabled}"
      @click="toggle()"
    >
      <div
        v-if="pre_option"
        class="select__main__pre-option"
      >
        {{ pre_option }}
      </div>
      <div
        class="select__main__label"
        :class="{'select__main__label--active': selected_count > 0}"
      >
        <template v-if="selected_count === 0">
          {{ placeholder }}
        </template>

        <template v-if="selected_count === 1">
          <span
            v-if="selected_option.full_name"
            class="full_name"
          >{{ selected_option.full_name }}</span>
          <span class="name">{{ selected_option.name }}</span>
        </template>

        <template v-if="selected_count > 1">
          {{ selected_count }} {{ multi_label }}
        </template>
      </div>

      <div class="select__main__arrow">
        <v-icon size="24">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>

    <div
      class="select__options"
      :class="{'select__options--displayed': unfold || show_options, 'select__options--unfold': unfold, 'disable': disabled}"
    >
      <div
        v-for="option in option_list"
        :key="option.value"
        class="select__options__item"
        :class="{
          'select__options__item--selected': option.selected,
          'invalid': isInvalidValue(option.value),
          'select__options__item--disabled': option.disabled,
        }"
        :title="invalid_message"
        @click="select(option)"
      >
        <v-icon
          v-if="visible_icon"
          size="24"
        >
          {{ option.selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon>

        <span
          v-if="option.full_name"
          class="full_name"
        >{{ option.full_name }}</span>
        <span
          v-if="option.name"
          class="name"
        >{{ option.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectElement',
  props: {
    options: Array,
    pre_option: String,
    multi: {
      default: true,
      type: Boolean,
    },
    // eslint-disable-next-line vue/prop-name-casing
    modelValue: [Array, Number, String],
    multi_label: String,
    trigger_close: Number,
    unfold: {
      default: false,
      type: Boolean,
    },
    placeholder: String,
    disabled: {
      default: false,
      type: Boolean,
    },
    visible_icon: {
      default: true,
      type: Boolean,
    },
    invalid_values: {
      default: () => [],
      type: Array,
    },
    invalid_message: String,
  },
  emits: [
    'change',
    'update:modelValue',
  ],
  data: () => ({
    option_list: [],
    invalid_option_list: [],
    show_options: false,
  }),
  computed: {
    selected_count() {
      return this.option_list
        .filter((option) => option.selected)
        .length;
    },
    selected_option() {
      return this.option_list
        .find((option) => option.selected);
    },
  },
  watch: {
    options: {
      handler() {
        this.initSelected();
      },
      deep: true,
    },
    trigger_close() {
      this.close();
    },
    invalid_values(values) {
      this.invalid_option_list = values;
    },
  },
  mounted() {
    this.initSelected();
  },
  methods: {
    close () {
      this.show_options = false;
    },
    initSelected () {
      this.option_list = [...this.options].map((option) => ({
        ...option,
        selected: false,
      }));

      if (this.multi) {
        this.option_list.forEach((option) => {
          if (this.modelValue.includes(option.value)) {
            option.selected = true;
          }
        });
      } else {
        this.option_list.forEach((option) => {
          if (this.modelValue === option.value) {
            option.selected = true;
          }
        });
      }
    },
    select (option) {
      if (this.disabled || option.disabled || this.isInvalidValue(option.value)) {
        return;
      }

      if (!this.multi) {
        this.option_list.forEach((option) => {
          option.selected = false;
        });
        this.show_options = false;
      }

      option.selected = !option.selected;

      let value = null;

      if (this.multi) {
        value = this.option_list
          .filter((option) => option.selected)
          .map((option) => option.value);
      } else {
        const option_selected = this.option_list.find((option) => option.selected);

        value = option_selected
          ? option_selected.value
          : -1;
      }

      this.$emit('update:modelValue', value);
      this.$emit('change');
    },
    toggle () {
      if (!this.disabled) {
        this.show_options = !this.show_options;
      }
    },
    isInvalidValue(value) {
      return this.invalid_option_list.includes(value);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/constants/variables.module.scss';

  .disable {
    cursor: default !important;
  }

  .select {
    position: relative;

    &__main {
      $p: &;

      align-items: center;
      border: 1px solid $blue-haze;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      height: 32px;
      justify-content: space-between;
      padding: 8px;

      &__label {
        color: $amethyst-smoke;
        font-size: 12px;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;

        &--active {
          color: $space-cadet;
        }
      }

      &__arrow {
        i {
          color: $amethyst-smoke;
        }
      }

      &__pre-option {
        font-size: 12px;
        color: grey;
        margin-right: 4px;
      }

      &--focus,
      &:not(.disable):hover {
        border: 1px solid $cornflower-blue;

        #{$p}__arrow {
          i {
            color: $cornflower-blue;
          }
        }
      }
    }

    &__options {
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 $blackcurrant;
      left: 0;
      max-height: 0;
      overflow: hidden;
      overflow-y: visible;
      position: absolute;
      top: 32px;
      transition: max-height .1s;
      min-width: 100%;
      z-index: 1;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: $quartz;
      }

      &::-webkit-scrollbar-thumb {
        background: $blue-haze;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $amethyst-smoke;
      }

      &--displayed {
        max-height: 200px;
      }

      &--unfold {
        border: 1px solid $blue-haze;
        box-shadow: none;
        position: relative;
        top: 0;

        &.disable .select__options__item {
          cursor: not-allowed !important;
        }
      }

      &__item {
        align-items: center;
        color: $amethyst-smoke;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        font-weight: 500;
        padding: 10px 8px;

        .v-icon {
          color: $amethyst-smoke;
          margin-right: 5px;
        }

        &--selected,
        &:hover {
          background-color: $quartz;
          color: $space-cadet;

          .v-icon {
            color: $cornflower-blue
          }
        }

        &--disabled {
          cursor: not-allowed;
        }
      }
      &__item.invalid {
        cursor: not-allowed;
        .name {
          color: $blue-haze;
        }
        &:hover {
          background-color: initial;
        }
      }
    }
  }
  .full_name {
    margin-right: 4px;
  }

  .name {
    color: $amethyst-smoke;
  }
</style>
