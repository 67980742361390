export default {
  "fr": {
    "hobby_type_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts / Loisirs créatifs"])},
    "hobby_type_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorties / Voyages"])},
    "hobby_type_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports"])},
    "hobby_type_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
  },
  "en": {
    
  }
}