import { axios_nomination } from '@/plugins/axios';
import configuration from '@/configurations';

class SchoolRepository {

  /**
   * @returns {Promise}
   */
  async searchSchools(params) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/schools`,
        {
          params,
        }
      );

    return result.data;
  }
}

const school_repository = new SchoolRepository();

export {
  school_repository,
};
