import { axios_nomination } from '@/plugins/axios';
import configuration from '@/configurations';

class ReferentialRepository {

  /**
   * @returns {Promise}
   */
  async getIDCC(params) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/referential/idcc`,
        {
          params,
        }
      );

    return result.data;
  }
}

const referential_repository = new ReferentialRepository();

export {
  referential_repository,
};
