import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class NotificationRepository {

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async getSettings() {
    const result = await axios_nomination.get(`${configuration.api_host}/v1/notifications/settings`);

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async updateSettings(data) {
    const result = await axios_nomination
      .put(
        `${configuration.api_host}/v1/notifications/settings`,
        data
      );

    return result.data;
  }
}

const notification_repository = new NotificationRepository();

export {
  notification_repository,
};
