export default {
  "fr": {
    "person_hobby_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Athlétisme"])},
    "person_hobby_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviation"])},
    "person_hobby_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviron"])},
    "person_hobby_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bourse"])},
    "person_hobby_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bricolage"])},
    "person_hobby_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brocante, Enchères"])},
    "person_hobby_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caritatif / Associatif"])},
    "person_hobby_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinéma"])},
    "person_hobby_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course automobile"])},
    "person_hobby_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décoration"])},
    "person_hobby_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equitation"])},
    "person_hobby_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expositions artistiques"])},
    "person_hobby_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Football"])},
    "person_hobby_id_list_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footing"])},
    "person_hobby_id_list_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomie"])},
    "person_hobby_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golf"])},
    "person_hobby_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique"])},
    "person_hobby_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jardinage"])},
    "person_hobby_id_list_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
    "person_hobby_id_list_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natation"])},
    "person_hobby_id_list_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Networking"])},
    "person_hobby_id_list_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NTIC"])},
    "person_hobby_id_list_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opéra"])},
    "person_hobby_id_list_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peinture / Dessin"])},
    "person_hobby_id_list_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roller"])},
    "person_hobby_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rugby"])},
    "person_hobby_id_list_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping"])},
    "person_hobby_id_list_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski"])},
    "person_hobby_id_list_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports de combat"])},
    "person_hobby_id_list_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Squash"])},
    "person_hobby_id_list_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennis"])},
    "person_hobby_id_list_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Théatre"])},
    "person_hobby_id_list_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voile"])},
    "person_hobby_id_list_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages"])},
    "person_hobby_id_list_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
    "person_hobby_id_list_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cigares"])},
    "person_hobby_id_list_37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oenologie"])},
    "person_hobby_id_list_38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoga"])},
    "person_hobby_id_list_39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpinisme / Escalade"])},
    "person_hobby_id_list_40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badminton"])},
    "person_hobby_id_list_41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basket Ball"])},
    "person_hobby_id_list_42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chasse"])},
    "person_hobby_id_list_43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrime"])},
    "person_hobby_id_list_44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handball"])},
    "person_hobby_id_list_45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moto"])},
    "person_hobby_id_list_46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographie"])},
    "person_hobby_id_list_47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano"])},
    "person_hobby_id_list_48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plongée sous-marine"])},
    "person_hobby_id_list_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volley-Ball"])},
    "person_hobby_id_list_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musique"])},
    "person_hobby_id_list_52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danse"])},
    "person_hobby_id_list_53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musique classique"])},
    "person_hobby_id_list_54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musique contemporaine"])},
    "person_hobby_id_list_56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyclisme / Vélo"])}
  },
  "en": {
    
  }
}