'use strict';

const TYPES = {
  ALERT: 1,
  SEARCH: 2,
  SEGMENT_CONTACT: 3,
  SEGMENT_ACCOUNT: 4,
  PROSPECTING_CONTACT: 5,
  PROSPECTING_ACCOUNT: 6,
  CAMPAIGN_CONTACT: 7,
  CAMPAIGN_ACCOUNT: 8,
  ENGAGED_CONTACT: 9,
  CONSULTED_CONTACT: 10,
  EXPORTED_CONTACT: 11,
  CONSULTED_ACCOUNT: 12,
  EXPORTED_ACCOUNT: 13,
  ADDRESS_BOOK: 14,
  CLIENTS: 15,
  NETWORK_SCHOOL_CONTACT: 16,
  NETWORK_COMPANY_CONTACT: 17,
  LEADS: 18,
  REJECTED_SUGGESTION: 19,
  PROSPECTS: 20,
  COMPETITORS: 21,
  KEY_ACCOUNTS: 22,
  CAMPAIGN_QUERY_NPAI: 23,
  CAMPAIGN_QUERY_SOFT: 24,
  CAMPAIGN_QUERY_OVERQUOTA: 25,
  CAMPAIGN_QUERY_OPENERS: 26,
  CAMPAIGN_QUERY_UNSUBSCRIBERS: 27,
  CAMPAIGN_QUERY_CLICKERS: 28,
  CAMPAIGN_QUERY_INACTIVES: 29,
  CAMPAIGN_QUERY_TARGETS: 30,
  CAMPAIGN_QUERY_OPENERS_NOT_CLICKERS: 31,
  CAMPAIGN_QUERY_HARD_BOUNCE: 32,
  INTERACTION_MAIL_SEND: 33,
  INTERACTION_CALLED: 34,
  INTERACTION_MAIL_OPENED: 35,
  INTERACTION_MAIL_CLICKED: 36,
  STATUS_ENGAGED_CONTACT: 37,
  STATUS_INTERESTED_CONTACT: 38,
  INTERACTION_APPOINTMENT: 39,
  INTERACTION_NO_POTENTIAL: 40,
  EXPORT_CRM_CONTACTS: 41,
  ORGANIZATION_WALLET: 42,
  PUSH_TO_CRM: 43,
  RSS_FEED: 44,
  INTERACTION_MAIL_REPLIED: 45,
  INTERACTION_MAIL_BOUNCED: 46,
  IGNORE_ALL_CONTACT_LIST: 47,
  IGNORE_ENGAGE_CONTACT_LIST: 48,
  IGNORE_CAMPAIGN_CONTACT_LIST: 49,
  IGNORE_CUSTOM_CONTACT_LIST: 50,
  EXPORT_CRM_COMPANIES: 51,
};

const CATEGORIES = {
  SHARED: 'shared',
  ASSIGNED: 'assigned',
};

const SAVED_SEARCHES_LIMIT = 10;
const SEGMENTS_LIMIT = 10;

const QUERY_CONSTANTS = {
  CATEGORIES,
  SAVED_SEARCHES_LIMIT,
  SEGMENTS_LIMIT,
  TYPES,
};

export {
  QUERY_CONSTANTS,
};
