export default {
  "fr": {
    "type_list_id_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les listes"])},
    "type_list_id_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes listes de contacts"])},
    "type_list_id_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes listes d'entreprises"])},
    "type_list_id_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes d'ouvreurs/cliqueurs"])},
    "type_list_id_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes automatiques"])},
    "type_list_id_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes désinscrits"])}
  },
  "en": {
    
  }
}