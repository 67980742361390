export default {
  "fr": {
    "company_industry_type_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration publique nationale"])},
    "company_industry_type_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration territoriale"])},
    "company_industry_type_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement local"])},
    "company_industry_type_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutions internationales"])},
    "company_industry_type_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes bancaires"])},
    "company_industry_type_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance, Réassurance, caisses de retraite"])},
    "company_industry_type_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banques de détail"])},
    "company_industry_type_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banques de financement et d'investissement"])},
    "company_industry_type_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banques nationales, autorités de tutelle, institutions financières, etc."])},
    "company_industry_type_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banques privées, gestion de fortune"])},
    "company_industry_type_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital Investissement, Business Angels, sociétés de participation"])},
    "company_industry_type_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financements spécialisés (leasing, crédit à la consommation)"])},
    "company_industry_type_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion d'actifs, services aux investisseurs, sociétés de bourse"])},
    "company_industry_type_id_list_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres sociétés financières"])},
    "company_industry_type_id_list_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biens de consommation alimentaires"])},
    "company_industry_type_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droguerie, parfumerie, hygiène, cosmétiques"])},
    "company_industry_type_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipement de la maison, électroménager"])},
    "company_industry_type_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipement de la personne (vêtements, accessoires)"])},
    "company_industry_type_id_list_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support, maintenance et logistique"])},
    "company_industry_type_id_list_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres biens de consommation non alimentaires"])},
    "company_industry_type_id_list_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTP, construction"])},
    "company_industry_type_id_list_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilier, promotion immobilière"])},
    "company_industry_type_id_list_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil en audit, expertise comptable"])},
    "company_industry_type_id_list_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil en management, stratégie"])},
    "company_industry_type_id_list_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil en relations publiques, communication, marketing"])},
    "company_industry_type_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil juridique, cabinets d'avocats"])},
    "company_industry_type_id_list_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil RH, recrutement, outplacement"])},
    "company_industry_type_id_list_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingénierie, certifications et inspections techniques"])},
    "company_industry_type_id_list_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres prestations de conseil"])},
    "company_industry_type_id_list_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaînes de restauration"])},
    "company_industry_type_id_list_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution BtoB, négoce"])},
    "company_industry_type_id_list_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution spécialisée"])},
    "company_industry_type_id_list_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Commerce, VAD"])},
    "company_industry_type_id_list_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grande distribution alimentaire"])},
    "company_industry_type_id_list_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres sociétés de distribution"])},
    "company_industry_type_id_list_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie, courrier"])},
    "company_industry_type_id_list_37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériaux de construction et extraction d'autres matériaux (hors énergie)"])},
    "company_industry_type_id_list_38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logiciels et services informatiques"])},
    "company_industry_type_id_list_39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommunications"])},
    "company_industry_type_id_list_40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aéronautique, défense"])},
    "company_industry_type_id_list_41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agroalimentaire, agriculture"])},
    "company_industry_type_id_list_42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobiles, équipementiers"])},
    "company_industry_type_id_list_43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chimie (fine et lourde), plastique"])},
    "company_industry_type_id_list_44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction mécanique, métallurgie, sidérurgie, machines-outils"])},
    "company_industry_type_id_list_45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie"])},
    "company_industry_type_id_list_47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrie de l'emballage et fabrication d'autres matériaux"])},
    "company_industry_type_id_list_48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxe"])},
    "company_industry_type_id_list_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse, édition, imprimerie"])},
    "company_industry_type_id_list_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration collective"])},
    "company_industry_type_id_list_51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités des industries"])},
    "company_industry_type_id_list_52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de médias"])},
    "company_industry_type_id_list_53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture & Loisirs"])},
    "company_industry_type_id_list_54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portails, pure players Internet"])},
    "company_industry_type_id_list_55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinateurs & Electronique grand public"])},
    "company_industry_type_id_list_56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D, pôles de compétitivité"])},
    "company_industry_type_id_list_57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télévision, radio, cinéma"])},
    "company_industry_type_id_list_58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associations, fédérations, syndicats"])},
    "company_industry_type_id_list_59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondations"])},
    "company_industry_type_id_list_60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement, recherche, formation professionnelle"])},
    "company_industry_type_id_list_61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etablissements médicaux, centre de soins"])},
    "company_industry_type_id_list_62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérim"])},
    "company_industry_type_id_list_63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régies publicitaires, affichage"])},
    "company_industry_type_id_list_64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé, pharmacie, biotech"])},
    "company_industry_type_id_list_65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services à la personne, action sociale"])},
    "company_industry_type_id_list_66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourisme, hôtellerie"])},
    "company_industry_type_id_list_67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services aux entreprises ou aux collectivités"])},
    "company_industry_type_id_list_68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériels et composants électroniques, électriques, informatique professionnelle"])},
    "company_industry_type_id_list_69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services environnementaux, facility management"])},
    "company_industry_type_id_list_70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports aériens"])},
    "company_industry_type_id_list_71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports ferroviaires et urbains (trains, bus & cars, métro, taxis)"])},
    "company_industry_type_id_list_72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports maritimes et fluviaux"])},
    "company_industry_type_id_list_73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports routiers (camions)"])},
    "company_industry_type_id_list_75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logement social"])},
    "company_industry_type_id_list_76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastructures de transport"])},
    "company_industry_type_id_list_78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation Client"])},
    "company_industry_type_id_list_79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editeurs de logiciels"])},
    "company_industry_type_id_list_80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESN (ex-SSII)"])},
    "company_industry_type_id_list_81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plateformes digitales"])}
  },
  "en": {
    
  }
}