'use strict';

const TAG_PLURAL_MAPPING = {
  "jobPosition": "fonctions",
  "jobPositionKeyword": "mots-clés fonctions",
  "sectorCategory": "secteurs",
  "sectorIDCC": "IDCC",
  "sectorNaf": "codes NAF",
  "sectorKeyword": "mots-clés secteurs",
  "localisations": "zones géographiques",
  "localisationDepartment": "??departments",
  "localisationCity": "??cities",
  "localisationCountry": "zones géographiques",
  "cacStartDate": "Date mandat",
  "closureDate": "Date de clôture",
  "companyProfileCriteria": "critères profil entreprise",
  "companyProfileCriteria2": "critères entreprises",
  "identifiers": "identifiants",
  "decisionMakerNews": "critères actu décideur",
  "decisionMakerHierarchicalLevel": "niveaux hiérarchiques",
  "decisionMakerSchool": "critères écoles",
  "decisionMakerSchoolType": "critères écoles",
  "decisionMakerPreviousJob": "critères parcours décideur",
  "decisionMakerHobbies": "hobbies",
  "decisionMakerCommunication": "Coordonnées disponibles",
  "companyNews": "critères actus entreprises",
  "companyProfileBrand": "marques",
  "companyList": "entreprises",
  "personList": "?? persones",
  "includeList": "Recherche limitée à ?? listes",
  "excludeList": "listes exclues",
  "includeSegment": "Recherche limitée à ?? segments",
  "excludeSegment": "segments exclus",
  "includeCampaign": "Recherche limitée à ?? campagnes",
  "excludeCampaign": "campagnes exclues",
  "includeSequence": "Recherche limitée à ?? sequences",
  "excludeSequence": "sequences exclues",
  "groupHeadCountryList": "Siège groupe : ?? pays sélectionnés",
  "groupHeadCountryTagIdList": "Siège groupe : ?? pays sélectionnés",
  "inMyNetwork": "Dans mes réseaux",
};

export {
  TAG_PLURAL_MAPPING,
};
