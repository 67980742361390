export default {
  "fr": {
    "customer_inquiry_sub_type_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décideur n'occupe plus ce poste"])},
    "customer_inquiry_sub_type_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’email du décideur semble erroné"])},
    "customer_inquiry_sub_type_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone du décideur semble erroné"])},
    "customer_inquiry_sub_type_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les coordonnées de l'assistant(e) semblent erronées"])},
    "customer_inquiry_sub_type_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'url Linkedin du décideur semble erronée"])},
    "customer_inquiry_sub_type_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
    "customer_inquiry_sub_type_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse de l'entreprise"])},
    "customer_inquiry_sub_type_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de standard de l'entreprise"])},
    "customer_inquiry_sub_type_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La structure du groupe ou des filiales de l'entreprise"])},
    "customer_inquiry_sub_type_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données financières de l'entreprise"])},
    "customer_inquiry_sub_type_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
    "customer_inquiry_sub_type_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un numéro de mobile"])},
    "customer_inquiry_sub_type_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le mobile"])},
    "customer_inquiry_sub_type_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le SIREN ou SIRET de l'entreprise"])},
    "customer_inquiry_sub_type_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recherche d'un décideur manquant"])}
  },
  "en": {
    
  }
}