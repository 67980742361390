export default {
  "fr": {
    "advertiser_tag_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonceurs uniquement"])},
    "advertiser_tag_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 100 Annonceurs"])},
    "advertiser_tag_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En croissance"])},
    "advertiser_tag_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En décroissance"])}
  },
  "en": {
    
  }
}