export default {
  "fr": {
    "job_function_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
    "job_function_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général, DGA, directeur exécutif"])},
    "job_function_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur des opérations"])},
    "job_function_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondateur"])},
    "job_function_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérant"])},
    "job_function_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrétaire général"])},
    "job_function_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associé"])},
    "job_function_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable communication"])},
    "job_function_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable communication externe, relations presse"])},
    "job_function_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable communication interne"])},
    "job_function_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable relations publiques, institutionnelles"])},
    "job_function_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable communication financière"])},
    "job_function_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable communication événementielle"])},
    "job_function_id_list_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable systèmes d'information"])},
    "job_function_id_list_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable informatique décisionnelle, bases de données"])},
    "job_function_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable informatique"])},
    "job_function_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable télécommunications"])},
    "job_function_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable de projets informatiques"])},
    "job_function_id_list_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable systèmes, réseaux et télécoms"])},
    "job_function_id_list_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable sécurité informatique"])},
    "job_function_id_list_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable développement durable, RSE"])},
    "job_function_id_list_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur publication, éditeur"])},
    "job_function_id_list_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable diffusion, abonnement"])},
    "job_function_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur des programmes, production"])},
    "job_function_id_list_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur de la rédaction, rédacteur en chef"])},
    "job_function_id_list_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef de rubrique, journaliste"])},
    "job_function_id_list_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producteur"])},
    "job_function_id_list_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable marketing"])},
    "job_function_id_list_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable produit, marque"])},
    "job_function_id_list_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable trade ou retail marketing, category management"])},
    "job_function_id_list_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable marketing direct, relationnel"])},
    "job_function_id_list_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable études marketing, marketing stratégique"])},
    "job_function_id_list_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable partenariats, licensing"])},
    "job_function_id_list_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable publicité"])},
    "job_function_id_list_37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable digital"])},
    "job_function_id_list_38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable des contenus"])},
    "job_function_id_list_39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable webmarketing"])},
    "job_function_id_list_40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable e-commerce"])},
    "job_function_id_list_42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable commercial"])},
    "job_function_id_list_43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable grands comptes"])},
    "job_function_id_list_44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable ventes"])},
    "job_function_id_list_46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable export"])},
    "job_function_id_list_47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable business development"])},
    "job_function_id_list_48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable distribution, réseau commercial"])},
    "job_function_id_list_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable surfaces commerciales (magasin, hypermarché)"])},
    "job_function_id_list_51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable artistique, création"])},
    "job_function_id_list_52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable business unit"])},
    "job_function_id_list_53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur régional"])},
    "job_function_id_list_54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur international"])},
    "job_function_id_list_55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur conseil, consultant senior"])},
    "job_function_id_list_56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant, chargé de mission"])},
    "job_function_id_list_57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avocat, collaborateur juridique"])},
    "job_function_id_list_59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable ressources humaines"])},
    "job_function_id_list_60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable rémunération"])},
    "job_function_id_list_61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable relations sociales"])},
    "job_function_id_list_62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable développement RH"])},
    "job_function_id_list_63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable formation"])},
    "job_function_id_list_64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable recrutement"])},
    "job_function_id_list_65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable juridique"])},
    "job_function_id_list_66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable risques, conformité, déontologie"])},
    "job_function_id_list_68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable propriété intellectuelle, industrielle"])},
    "job_function_id_list_69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable affaires médicales"])},
    "job_function_id_list_70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable achats"])},
    "job_function_id_list_72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable achats matières premières, énergie"])},
    "job_function_id_list_75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable achats publics"])},
    "job_function_id_list_76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable achats hors production, prestations intellectuelles"])},
    "job_function_id_list_80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable travel management"])},
    "job_function_id_list_81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable supply chain, logistique"])},
    "job_function_id_list_82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable flotte de transport"])},
    "job_function_id_list_83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable technique"])},
    "job_function_id_list_84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable qualité"])},
    "job_function_id_list_85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable hygiène, sécurité, environnement"])},
    "job_function_id_list_86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable bureau d'études, études techniques"])},
    "job_function_id_list_87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable R&D, innovation"])},
    "job_function_id_list_88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable stratégie, développement"])},
    "job_function_id_list_89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable études"])},
    "job_function_id_list_92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable immobilier, urbanisme, habitat"])},
    "job_function_id_list_93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable urbanisme, habitat"])},
    "job_function_id_list_94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable usine"])},
    "job_function_id_list_95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable production"])},
    "job_function_id_list_96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable industriel"])},
    "job_function_id_list_97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable financier"])},
    "job_function_id_list_98": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable fiscal"])},
    "job_function_id_list_99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable services généraux"])},
    "job_function_id_list_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable contrôle de gestion"])},
    "job_function_id_list_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable audit"])},
    "job_function_id_list_102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable comptable"])},
    "job_function_id_list_103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable trésorerie"])},
    "job_function_id_list_104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable consolidation"])},
    "job_function_id_list_105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général des services"])},
    "job_function_id_list_106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur de cabinet"])},
    "job_function_id_list_107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maire"])},
    "job_function_id_list_108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut fonctionnaire"])},
    "job_function_id_list_110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable corporate finance et capital investissement"])},
    "job_function_id_list_111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable opérations sur les marchés"])},
    "job_function_id_list_112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable études économiques, analyse financière"])},
    "job_function_id_list_113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable gestion des risques financiers"])},
    "job_function_id_list_114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable banque de détail"])},
    "job_function_id_list_115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable banque d'affaires, corporate finance"])},
    "job_function_id_list_116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable gestion de fortune, de patrimoine"])},
    "job_function_id_list_118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable assurances, actuariat"])},
    "job_function_id_list_119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du conseil administration"])},
    "job_function_id_list_120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du conseil administration"])},
    "job_function_id_list_121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du conseil surveillance"])},
    "job_function_id_list_122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du conseil surveillance"])},
    "job_function_id_list_123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du comité d'audit"])},
    "job_function_id_list_124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du comité d'audit"])},
    "job_function_id_list_125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du comité de rémunérations, nominations"])},
    "job_function_id_list_126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du comité de rémunérations, nominations"])},
    "job_function_id_list_127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du comité de direction, exécutif ou directoire"])},
    "job_function_id_list_128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du comité de direction, exécutif ou directoire"])},
    "job_function_id_list_129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du comité financier, stratégique"])},
    "job_function_id_list_130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du comité financier, stratégique"])},
    "job_function_id_list_131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du comité d'éthique"])},
    "job_function_id_list_132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du comité d'éthique"])},
    "job_function_id_list_133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux comptes"])},
    "job_function_id_list_134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Censeur"])},
    "job_function_id_list_135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadre dirigeant"])},
    "job_function_id_list_136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadre"])},
    "job_function_id_list_137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employé"])},
    "job_function_id_list_138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable expérience client"])},
    "job_function_id_list_139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable crédit management"])},
    "job_function_id_list_140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable mécénat, sponsoring"])},
    "job_function_id_list_141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président codir élargi"])},
    "job_function_id_list_142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre codir élargi"])},
    "job_function_id_list_143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur d'hôtel ou palace"])},
    "job_function_id_list_144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président / directeur d'association, fondation"])},
    "job_function_id_list_145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délégué général"])},
    "job_function_id_list_146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable d'établissement, de site"])},
    "job_function_id_list_147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable de la transformation"])},
    "job_function_id_list_148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable organisation"])},
    "job_function_id_list_149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable fusions et acquisitions"])},
    "job_function_id_list_150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur de projets, responsable de mission"])},
    "job_function_id_list_151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable contentieux, recouvrement"])},
    "job_function_id_list_152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable administratif"])},
    "job_function_id_list_153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable data, CRM"])},
    "job_function_id_list_154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable protection des données personnelles"])},
    "job_function_id_list_155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur / responsable maintenance"])},
    "job_function_id_list_158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du CSE"])}
  },
  "en": {
    
  }
}