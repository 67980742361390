<template>
  <section>
    <div
      v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_ACTIVITY)"
    >
      <p class="sidebar-title">
        {{ $t('menu_group_my_activity') }}
      </p>

      <router-link
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES)"
        class="iconized"
        :to="{ path: '/personal-space/saved-searches'}"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-magnify</v-icon>
        </span>
        <span>{{ $t('link_my_saved_searches') }}</span>
      </router-link>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS)"
        class="iconized"
        :href="`${configuration.desk_v1_host}/personal-space/notifications`"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-bell</v-icon>
        </span>
        <span>{{ $t('link_notifications') }}</span>
      </a>

      <router-link
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_REQUESTS)"
        class="iconized"
        :to="{ path: '/reports'}"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-binoculars</v-icon>
        </span>
        <span>{{ $t('link_requests_and_inquiries') }}</span>
      </router-link>

      <router-link
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_EXPORT)"
        class="iconized"
        to="/personal-space/exports"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-download</v-icon>
        </span>
        <span>{{ $t('link_exports') }}</span>
      </router-link>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION)"
        class="iconized"
        :href="`${configuration.desk_v1_host}/personal-space/credit-consumption`"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-database</v-icon>
        </span>
        <span>{{ $t('link_credit_consumption') }}</span>
      </a>
    </div>

    <div
      v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_PARAMETERS)"
      class="sidebar-title-block"
    >
      <p class="sidebar-title">
        {{ $t('menu_group_parameters') }}
      </p>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_PROFILE)"
        class="iconized"
        :href="`${configuration.desk_v1_host}/personal-space/profile`"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-account</v-icon>
        </span>
        <span>{{ $t('link_profile') }}</span>
      </a>
      <router-link
        v-if="user_data.can_see_ai"
        class="iconized"
        to="/personal-space/my-ai"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-cog</v-icon>
        </span>
        <span>{{ $t('link_ai') }}</span>
      </router-link>

      <router-link
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_ACCOUNT_SEGMENTS)"
        class="iconized"
        to="/personal-space/company-segments"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-office-building-outline</v-icon>
        </span>
        <span>{{ $t('link_company_segments') }}</span>
      </router-link>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_CONTACT_SEGMENTS)"
        class="iconized"
        :href="`${configuration.desk_v1_host}/personal-space/contact-segments`"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-card-account-details-outline</v-icon>
        </span>
        <span>{{ $t('link_contact_segments') }}</span>
      </a>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_MY_ALERTS)"
        class="iconized"
        :href="`${configuration.desk_v1_host}/personal-space/alerts`"
      >
        <span class="iconized-icon">
          <v-icon size="small"> mdi-flash </v-icon>
        </span>
        <span>{{ $t('link_alerts') }}</span>
      </a>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_TEMPLATES)"
        class="iconized"
        :href="`${configuration.desk_v1_host}/personal-space/templates`"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-file-document-outline</v-icon>
        </span>
        <span>{{ $t('link_templates_and_signatures') }}</span>
      </a>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_TEMPLATES) && !is_licence_sender"
        class="iconized"
        :href="`${configuration.desk_v1_host}/personal-space/bat`"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-at</v-icon>
        </span>
        <span>{{ $t('link_test_emails') }}</span>
      </a>
    </div>

    <div
      v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT)"
      class="sidebar-title-block"
    >
      <p class="sidebar-title">
        {{ $t('menu_group_my_account') }}
      </p>

      <a
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION)"
        class="iconized"
        :href="$router.resolve(
          {
            path: '/personal-space/email-connection'
          })
          .href"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-email</v-icon>
        </span>
        <span>{{ $t('link_email_connection') }}</span>
        <i
          v-if="!nylas_credentials?.email"
          class="fa fa-exclamation-circle email-not-connected"
        />
      </a>

      <router-link
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS)"
        class="iconized"
        to="/personal-space/notifications/settings"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-bell</v-icon>
        </span>
        <span>{{ $t('link_notifications_settings') }}</span>
      </router-link>

      <router-link
        v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD)"
        class="iconized"
        to="/personal-space/change-password"
      >
        <span class="iconized-icon">
          <v-icon size="small">mdi-lock</v-icon>
        </span>
        <span>{{ $t('link_password') }}</span>
      </router-link>

      <div class="sidebar-title-block">
        <a
          class="iconized"
          @click="goLogout()"
        >
          <span class="iconized-icon">
            <v-icon size="small">mdi-power</v-icon>
          </span>
          <span>{{ $t('link_logout') }}</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { PERMISSIONS, LICENSE_CONSTANTS } from '@/constants';

import configuration from '@/configurations';

export default {
  name: 'PersonalSpaceSidebarElement',
  data: () => ({
    configuration,
    PERMISSIONS,
  }),
  computed: {
    ...mapGetters({
      user_data: 'user/userData',
      nylas_credentials: 'nylas/nylasCredentials',
    }),
    is_licence_sender() {
      // eslint-disable-next-line vue/max-len
      return this.user_data.organization_user_license_list[0].license.id === LICENSE_CONSTANTS.SENDER.ID;
    },
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
    }),
    goLogout() {
      this.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.email-not-connected {
  margin-left: 70px;
}

.sidebar-title {
  margin: 0px 0 16px 0;
  height: 12px;
  letter-spacing: 2px;
  line-height: 12px;
  color: $blue-haze;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.sidebar-title-block {
  margin-top: 30px;
}

.iconized-icon {
  margin-right: 12px;
  text-align: center;
}

.iconized-lock-icon {
  margin-left: 4px;
  margin-right: 15px;
}

.iconized-chevron {
  margin-left: 5px;
}

.iconized {
  margin-bottom: 11px;
  height: 16px;
  line-height: 16px;
  align-items: center;
  color: $amethyst-smoke;
  display: flex;
  font-weight: 500;
  outline: none;
  text-decoration: none;
  letter-spacing: 0;
  border: none;

  &:deep(i) {
    color: $amethyst-smoke !important;
  }

  &:deep(.email-not-connected) {
    color: $ripe-pumpkin !important;
  }

  &:hover {
    border-right: 3px solid $cornflower-blue;
    color: $haiti;

      &:deep(i) {
        color: $cornflower-blue !important;
      }

      &:deep(.email-not-connected) {
        color: $ripe-pumpkin !important;
      }
  }
}

.router-link-exact-active {
  border-right: 3px solid $cornflower-blue !important;
  color: $haiti !important;

  &:deep(i) {
    color: $cornflower-blue !important;
  }
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/personal-space-sidebar-element.json"></i18n>
