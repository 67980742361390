'use strict';

const LICENSE_CONSTANTS = {
  SALES: {
    ID: 1,
    LABEL: 'sales',
  },
  //SmartCampaign
  MARKETING: {
    ID: 2,
    LABEL: 'marketing',
  },
  SALES_AND_MARKETING: {
    ID: 3,
    LABEL: 'sales & marketing',
  },
  KAM: {
    ID: 4,
    LABEL: 'kam',
  },
  INTRANET: {
    ID: 5,
    LABEL: 'intranet',
  },
  CONNECTOR: {
    ID: 6,
    LABEL: 'connector crm',
  },
  //Recherches & Listes
  SEARCH_AND_LISTS: {
    ID: 7,
    LABEL: 'search and lists',
  },
  SALES_PME: {
    ID: 8,
    LABEL: 'sales pme',
  },
  INSIGHT: {
    ID: 9,
    LABEL: 'insight',
  },
  SENDER: {
    ID: 10,
    LABEL: 'sender',
  },
  SALES_AUTOMATION: {
    ID: 11,
    LABEL: 'sales automation',
  },
  SMART_DATA: {
    ID: 12,
    LABEL: 'smart data',
  },
  SALES_AUTOMATION_V2: {
    ID: 13,
    LABEL: 'sales automation v2',
  },
  SMART_CAMPAIGN_V2: {
    ID: 14,
    LABEL: 'smart campaign v2',
  },
  SENDER_V2: {
    ID: 15,
    LABEL: 'sender v2',
  },
};

export {
  LICENSE_CONSTANTS,
};
