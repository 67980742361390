import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class NewsletterRepository {

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async getUserConfiguration(input) {
    const {
      organization_id,
      user_id,
    } = input;

    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/newsletter/organization/${organization_id}/user/${user_id}/configuration`);

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async updateUserConfiguration(input) {
    const {
      data,
      organization_id,
      user_id,
    } = input;

    const result = await axios_nomination
      .put(
        `${configuration.api_host}/v1/newsletter/organization/${organization_id}/user/${user_id}/configuration`,
        data
      );

    return result.data;
  }
}

const newsletter_repository = new NewsletterRepository();

export {
  newsletter_repository,
};
