export default {
  "fr": {
    "company_office_address_region_id_list_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auvergne-Rhône-Alpes"])},
    "company_office_address_region_id_list_102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bourgogne-Franche-Comté"])},
    "company_office_address_region_id_list_103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bretagne"])},
    "company_office_address_region_id_list_104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre-Val-de-Loire"])},
    "company_office_address_region_id_list_105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand Est"])},
    "company_office_address_region_id_list_106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauts-de-France"])},
    "company_office_address_region_id_list_107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île-de-France"])},
    "company_office_address_region_id_list_108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normandie"])},
    "company_office_address_region_id_list_109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Aquitaine"])},
    "company_office_address_region_id_list_110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occitanie"])},
    "company_office_address_region_id_list_111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de la Loire"])},
    "company_office_address_region_id_list_112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provence-Côte-d'Azur-Monaco-Corse"])},
    "company_office_address_region_id_list_113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D.R.O.M."])},
    "company_office_address_region_id_list_114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C.O.M."])}
  },
  "en": {
    
  }
}