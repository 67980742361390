export default {
  "fr": {
    "cac_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date mandat"])},
    "closure_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture"])},
    "completed_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience renseignée"])},
    "completed_formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation renseignée"])},
    "group_heads_and_their_subsidiaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Têtes de groupes et leurs filiales"])},
    "group_head_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiale d'un groupe "])},
    "group_heads_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Têtes de groupes uniquement"])},
    "headquarters_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siège uniquement"])},
    "in_my_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans mon réseau"])},
    "max_contact_per_company": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 contact par entreprise"]), _normalize([_interpolate(_named("count")), " contacts par entreprise"])])},
    "one_cac_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 commissaire aux comptes"])},
    "one_campaign_excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 campagne exclue"])},
    "one_campaign_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche limitée à 1 campagne"])},
    "one_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 entreprise"])},
    "one_company_criterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 critère entreprise"])},
    "one_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 identifiant"])},
    "one_list_excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 liste exclue"])},
    "one_list_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche limitée à 1 liste"])},
    "one_segment_excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 segment exclu"])},
    "one_segment_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche limitée à 1 segment"])},
    "one_sequence_excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 séquence exclue"])},
    "one_sequence_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche limitée à 1 séquence"])},
    "one_hobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hobby"])},
    "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])},
    "unclassified_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise non côtée"])}
  },
  "en": {
    
  }
}