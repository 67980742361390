export default {
  "fr": {
    "news_tag_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomination"])},
    "news_tag_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "news_tag_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anniversaire"])},
    "news_tag_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conference"])},
    "news_tag_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])},
    "news_tag_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal d'affaire"])},
    "news_tag_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article de presse"])},
    "news_tag_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Contact"])},
    "news_tag_id_list_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissance externe"])},
    "news_tag_id_list_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement de l'activité"])},
    "news_tag_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau site"])},
    "news_tag_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversification"])},
    "news_tag_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet marketing"])},
    "news_tag_id_list_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement international"])},
    "news_tag_id_list_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet digital"])},
    "news_tag_id_list_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet IT"])},
    "news_tag_id_list_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement R&D"])},
    "news_tag_id_list_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises en difficulté"])},
    "news_tag_id_list_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutement et projet RH"])},
    "news_tag_id_list_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flotte Automobile"])},
    "news_tag_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d'offres formation"])},
    "news_tag_id_list_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicule de fonction"])},
    "news_tag_id_list_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de fonds"])},
    "news_tag_id_list_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d'offres IT"])},
    "news_tag_id_list_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d'offres marketing"])}
  },
  "en": {
    
  }
}