'use strict';

const ALL_HOURS = [
  {
    name: '02:00',
    value: 2,
  },
  {
    name: '03:00',
    value: 3,
  },
  {
    name: '04:00',
    value: 4,
  },
  {
    name: '05:00',
    value: 5,
  },
  {
    name: '06:00',
    value: 6,
  },
  {
    name: '07:00',
    value: 7,
  },
  {
    name: '08:00',
    value: 8,
  },
  {
    name: '09:00',
    value: 9,
  },
  {
    name: '10:00',
    value: 10,
  },
  {
    name: '11:00',
    value: 11,
  },
  {
    name: '12:00',
    value: 12,
  },
  {
    name: '13:00',
    value: 13,
  },
  {
    name: '14:00',
    value: 14,
  },
  {
    name: '15:00',
    value: 15,
  },
  {
    name: '16:00',
    value: 16,
  },
  {
    name: '17:00',
    value: 17,
  },
  {
    name: '18:00',
    value: 18,
  },
  {
    name: '19:00',
    value: 19,
  },
  {
    name: '20:00',
    value: 20,
  },
  {
    name: '21:00',
    value: 21,
  },
  {
    name: '22:00',
    value: 22,
  },
];

const ALL_DAYS = [
  {
    name: 'Lundi',
    value: 1,
  },
  {
    name: 'Mardi',
    value: 2,
  },
  {
    name: 'Mercredi',
    value: 3,
  },
  {
    name: 'Jeudi',
    value: 4,
  },
  {
    name: 'Vendredi',
    value: 5,
  },
  {
    name: 'Samedi',
    value: 6,
  },
  {
    name: 'Dimanche',
    value: 0,
  },
];

const SEQUENCE_COMPUTED_TASK_STATUS = {
  CREATED: {
    ID: 1,
  },
  DONE: {
    ID: 2,
  },
  DELETED: {
    ID: 3,
  },
};

const PREHEATING_STATUS_CONSTANTS = {
  TO_BE_DONE: {
    ID: 1,
  },
  ONGOING: {
    ID: 2,
  },
  DONE: {
    ID: 3,
  },
  DAYS: 10,
};
const SEQUENCE_DAILY_THRESHOLD = 150;
const SEQUENCE_HOUR = {
  START: {
    MIN: 7,
    MAX: 21,
  },
  END: {
    MIN: 8,
    MAX: 22,
  },
};
const SEQUENCE_NAME_MAX_LENGTH = 65;
const SEQUENCE_NAME_MIN_LENGTH = 1;

const SEQUENCE_PER_PAGE_COUNT = 20;

const SEQUENCE_STEP_TYPE_ID = {
  EMAIL: {
    ID: 2,
  },
  TASK: {
    ID: 1,
  },
};

const SEQUENCE_TARGET_PER_PAGE_COUNT = 20;

const SEQUENCE_TARGET_STATUS = {
  ONGOING: {
    ID: 2,
  },
  PAUSED: {
    ID: 1,
  },
  FINISHED: {
    ID: 3,
  },
};

const SEQUENCE_STATUS_CONSTANTS = {
  DRAFT: {
    ID: 1,
    URL_HANDLE: 'draft',
  },
  ONGOING: {
    ID: 2,
    URL_HANDLE: 'ongoing',
  },
  PAUSED: {
    ID: 3,
    URL_HANDLE: 'paused',
  },
  FINISHED: {
    ID: 4,
    URL_HANDLE: 'finished',
  },
  ARCHIVED: {
    ID: 5,
    URL_HANDLE: 'archived',
  },
  PROGRAMMED: {
    ID: 6,
    URL_HANDLE: 'programmed',
  },
  STEP_TYPE_ID_TASK: {
    ID: 1,
  },
  STEP_TYPE_ID_EMAIL: {
    ID: 2,
  },
  MAX_EMAIL_STEPS_PER_SEQUENCE: 6,
};

const SEQUENCE_EMAIL_EVENTS = {
  CLICKED: 'clicked',
  OPENED: 'opened',
  REPLIED: 'replied',
  SENT: 'sent',
};

const SEQUENCE_EVENTS = {
  MAIL_REPLIED: {
    ID: 1,
  },
  NO_EMAIL: {
    ID: 2,
  },
  OPTOUT: {
    ID: 3,
  },
};

const SEQUENCE_TASK_STATUS = {
  DONE: 'done',
  LATE: 'late',
  TODO: 'todo',
};

const SEQUENCE_RUNNER_STATUS = {
  DONE: {
    ID: 2,
  },
  TO_DO: {
    ID: 1,
  },
};

const SEQUENCE_ALARMING_SPAM_COUNT = 5;

const SEQUENCE_AUTOREPLY_DISCLAMER_END_DATE = new Date('2025-01-10');

export {
  ALL_DAYS,
  ALL_HOURS,
  PREHEATING_STATUS_CONSTANTS,
  SEQUENCE_AUTOREPLY_DISCLAMER_END_DATE,
  SEQUENCE_ALARMING_SPAM_COUNT,
  SEQUENCE_COMPUTED_TASK_STATUS,
  SEQUENCE_DAILY_THRESHOLD,
  SEQUENCE_EMAIL_EVENTS,
  SEQUENCE_EVENTS,
  SEQUENCE_HOUR,
  SEQUENCE_NAME_MAX_LENGTH,
  SEQUENCE_NAME_MIN_LENGTH,
  SEQUENCE_PER_PAGE_COUNT,
  SEQUENCE_RUNNER_STATUS,
  SEQUENCE_STATUS_CONSTANTS,
  SEQUENCE_STEP_TYPE_ID,
  SEQUENCE_TARGET_PER_PAGE_COUNT,
  SEQUENCE_TARGET_STATUS,
  SEQUENCE_TASK_STATUS,
};
