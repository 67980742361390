export default {
  "fr": {
    "advertiser_investment_media_type_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
    "advertiser_investment_media_type_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio"])},
    "advertiser_investment_media_type_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télévision"])},
    "advertiser_investment_media_type_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage"])},
    "advertiser_investment_media_type_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinéma"])},
    "advertiser_investment_media_type_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet display"])}
  },
  "en": {
    
  }
}