export default {
  "fr": {
    "company_office_address_department_id_list_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors de France"])},
    "company_office_address_department_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ain"])},
    "company_office_address_department_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aisne"])},
    "company_office_address_department_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allier"])},
    "company_office_address_department_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpes-de-Haute-Provence"])},
    "company_office_address_department_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes-Alpes"])},
    "company_office_address_department_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpes-Maritimes"])},
    "company_office_address_department_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ardèche"])},
    "company_office_address_department_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ardennes"])},
    "company_office_address_department_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ariège"])},
    "company_office_address_department_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aube"])},
    "company_office_address_department_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aude"])},
    "company_office_address_department_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveyron"])},
    "company_office_address_department_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouches-du-Rhône"])},
    "company_office_address_department_id_list_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calvados"])},
    "company_office_address_department_id_list_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantal"])},
    "company_office_address_department_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charente"])},
    "company_office_address_department_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charente-Maritime"])},
    "company_office_address_department_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cher"])},
    "company_office_address_department_id_list_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrèze"])},
    "company_office_address_department_id_list_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corse"])},
    "company_office_address_department_id_list_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte-d'Or"])},
    "company_office_address_department_id_list_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côtes-d'Armor"])},
    "company_office_address_department_id_list_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creuse"])},
    "company_office_address_department_id_list_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dordogne"])},
    "company_office_address_department_id_list_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doubs"])},
    "company_office_address_department_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drôme"])},
    "company_office_address_department_id_list_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eure"])},
    "company_office_address_department_id_list_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eure-et-Loir"])},
    "company_office_address_department_id_list_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finistère"])},
    "company_office_address_department_id_list_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gard"])},
    "company_office_address_department_id_list_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute-Garonne"])},
    "company_office_address_department_id_list_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gers"])},
    "company_office_address_department_id_list_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gironde"])},
    "company_office_address_department_id_list_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hérault"])},
    "company_office_address_department_id_list_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ille-et-Vilaine"])},
    "company_office_address_department_id_list_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indre"])},
    "company_office_address_department_id_list_37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indre-et-Loire"])},
    "company_office_address_department_id_list_38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isère"])},
    "company_office_address_department_id_list_39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jura"])},
    "company_office_address_department_id_list_40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landes"])},
    "company_office_address_department_id_list_41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loir-et-Cher"])},
    "company_office_address_department_id_list_42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loire"])},
    "company_office_address_department_id_list_43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute-Loire"])},
    "company_office_address_department_id_list_44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loire-Atlantique"])},
    "company_office_address_department_id_list_45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loiret"])},
    "company_office_address_department_id_list_46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lot"])},
    "company_office_address_department_id_list_47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lot-et-Garonne"])},
    "company_office_address_department_id_list_48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozère"])},
    "company_office_address_department_id_list_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maine-et-Loire"])},
    "company_office_address_department_id_list_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manche"])},
    "company_office_address_department_id_list_51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marne"])},
    "company_office_address_department_id_list_52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute-Marne"])},
    "company_office_address_department_id_list_53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayenne"])},
    "company_office_address_department_id_list_54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meurthe-et-Moselle"])},
    "company_office_address_department_id_list_55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meuse"])},
    "company_office_address_department_id_list_56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morbihan"])},
    "company_office_address_department_id_list_57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moselle"])},
    "company_office_address_department_id_list_58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nièvre"])},
    "company_office_address_department_id_list_59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nord"])},
    "company_office_address_department_id_list_60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oise"])},
    "company_office_address_department_id_list_61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orne"])},
    "company_office_address_department_id_list_62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas-de-Calais"])},
    "company_office_address_department_id_list_63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puy-de-Dôme"])},
    "company_office_address_department_id_list_64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyrénées-Atlantiques"])},
    "company_office_address_department_id_list_65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes-Pyrénées"])},
    "company_office_address_department_id_list_66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyrénées-Orientales"])},
    "company_office_address_department_id_list_67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas-Rhin"])},
    "company_office_address_department_id_list_68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut-Rhin"])},
    "company_office_address_department_id_list_69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhône"])},
    "company_office_address_department_id_list_70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute-Saône"])},
    "company_office_address_department_id_list_71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saône-et-Loire"])},
    "company_office_address_department_id_list_72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarthe"])},
    "company_office_address_department_id_list_73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savoie"])},
    "company_office_address_department_id_list_74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute-Savoie"])},
    "company_office_address_department_id_list_75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paris"])},
    "company_office_address_department_id_list_76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seine-Maritime"])},
    "company_office_address_department_id_list_77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seine-et-Marne"])},
    "company_office_address_department_id_list_78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yvelines"])},
    "company_office_address_department_id_list_79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deux-Sèvres"])},
    "company_office_address_department_id_list_80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
    "company_office_address_department_id_list_81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarn"])},
    "company_office_address_department_id_list_82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarn-et-Garonne"])},
    "company_office_address_department_id_list_83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Var"])},
    "company_office_address_department_id_list_84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaucluse"])},
    "company_office_address_department_id_list_85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendée"])},
    "company_office_address_department_id_list_86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vienne"])},
    "company_office_address_department_id_list_87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute-Vienne"])},
    "company_office_address_department_id_list_88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vosges"])},
    "company_office_address_department_id_list_89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yonne"])},
    "company_office_address_department_id_list_90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire-de-Belfort"])},
    "company_office_address_department_id_list_91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essonne"])},
    "company_office_address_department_id_list_92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauts-de-Seine"])},
    "company_office_address_department_id_list_93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seine-Saint-Denis"])},
    "company_office_address_department_id_list_94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Val-de-Marne"])},
    "company_office_address_department_id_list_95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Val-d'Oise"])},
    "company_office_address_department_id_list_97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "company_office_address_department_id_list_98": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "company_office_address_department_id_list_99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyane"])},
    "company_office_address_department_id_list_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "company_office_address_department_id_list_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Pierre-et-Miquelon"])},
    "company_office_address_department_id_list_102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "company_office_address_department_id_list_103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polynésie française"])},
    "company_office_address_department_id_list_104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Calédonie"])},
    "company_office_address_department_id_list_105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis et Futuna"])}
  },
  "en": {
    
  }
}