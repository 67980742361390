import {
  axios_nomination,
} from '@/plugins/axios';

import configuration from '@/configurations';
import {
  QUERY_CONSTANTS,
} from '@/constants';

class SearchRepository {
  constructor() {
    this.calls_in_progress = {};
  }

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async getContacts(criteria) {
    const params = {
      matched_queries_query_type_id_list: [
        QUERY_CONSTANTS.TYPES.IGNORE_ALL_CONTACT_LIST,
        QUERY_CONSTANTS.TYPES.IGNORE_ENGAGE_CONTACT_LIST,
        QUERY_CONSTANTS.TYPES.IGNORE_CAMPAIGN_CONTACT_LIST,
        QUERY_CONSTANTS.TYPES.IGNORE_CUSTOM_CONTACT_LIST,
      ],
    };

    if (criteria.contact_id_list !== undefined) {
      params.contact_id_list = criteria.contact_id_list;
      params.limit = criteria.contact_id_list.length;
    }

    if (criteria.contact_quicksearch_list !== undefined) {
      params.contact_quicksearch_list = criteria.contact_quicksearch_list;
    }

    if (criteria.query_id_list !== undefined) {
      params.query_id_list = criteria.query_id_list;
    }

    if (params.limit === undefined) {
      params.limit = 100;
    }

    const key = `getContacts:${btoa(JSON.stringify(params))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .post(
        `${configuration.api_host}/v1/aggregator-user-data/contacts`,
        params
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async searchAccount(params) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/accounts`,
      {
        params,
      }
    );

    return result.data;
  }

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async searchAccountActive(params) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/accounts/active`,
      {
        params,
      }
    );

    return result.data;
  }

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async getAccountsAll(criteria) {
    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/accounts/all`,
      criteria
    );

    return result.data;
  }

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async getContactsAll(criteria) {
    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/contacts/search/full`,
      criteria
    );

    return result.data;
  }

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async getContactsActive(criteria) {
    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/contacts/active`,
      criteria
    );

    return result.data;
  }

  // @TODO : Bad name
  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async getWtfContactsActive(criteria) {
    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/contacts/active`,
      {
        limit: 0,
        facet_list: [{
          field: "query_id_list",
          value_list: criteria.value_list,
          limit: 100,
        }],
      }
    );

    return result.data;
  }
}

const search_repository = new SearchRepository();

export {
  search_repository,
};
