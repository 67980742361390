'use strict';

const HISTORY_EVENT_TYPE_ID = {
  MAIL_REPLIED: 34,
  MAIL_AUTOREPLIED: 64,
};

export {
  HISTORY_EVENT_TYPE_ID,
};
