export default {
  "fr": {
    "company_size_id_list_211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grande entreprise"])},
    "company_size_id_list_208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise de taille intermédiaire"])},
    "company_size_id_list_207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start-up"])},
    "company_size_id_list_245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise de taille intermédiaire"])},
    "company_size_id_list_247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiales de type ETI"])},
    "company_size_id_list_251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiales de type PME"])},
    "company_size_id_list_365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petite entreprise"])},
    "company_size_id_list_367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne entreprise"])}
  },
  "en": {
    
  }
}