export default {
  "fr": {
    "company_structure_type_id_list_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise publique"])},
    "company_structure_type_id_list_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise familiale"])},
    "company_structure_type_id_list_102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiale d'un groupe étranger"])},
    "company_structure_type_id_list_132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise active à l'international"])},
    "company_structure_type_id_list_136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchises et assimilé"])},
    "company_structure_type_id_list_139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinTech"])},
    "company_structure_type_id_list_141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiale d'un groupe"])},
    "company_structure_type_id_list_160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise basée à l'étranger"])},
    "company_structure_type_id_list_162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise BtoB"])},
    "company_structure_type_id_list_167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise BtoB et BtoC"])},
    "company_structure_type_id_list_169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise BtoC"])},
    "company_structure_type_id_list_170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise innovante"])},
    "company_structure_type_id_list_171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GreenTech"])},
    "company_structure_type_id_list_376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HealthTech"])},
    "company_structure_type_id_list_378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DeepTech"])},
    "company_structure_type_id_list_379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EdTech"])},
    "company_structure_type_id_list_381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FoodAgritech"])}
  },
  "en": {
    
  }
}