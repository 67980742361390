export default {
  "fr": {
    "company_award_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 250 Editeurs de logiciels 2016"])},
    "company_award_id_list_347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Top RSE"])},
    "company_award_id_list_333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champions de la relation client"])},
    "company_award_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Global Brands"])},
    "company_award_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champions de la croissance"])},
    "company_award_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top e-commerce"])},
    "company_award_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top entreprises du luxe"])},
    "company_award_id_list_240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top des Editeurs de logiciels"])},
    "company_award_id_list_249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champions français de la croissance 2018"])},
    "company_award_id_list_255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Leaders 2018"])},
    "company_award_id_list_261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BPI ETI 2020"])},
    "company_award_id_list_263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start-up où investir"])},
    "company_award_id_list_317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top ESN"])},
    "company_award_id_list_323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next 40"])},
    "company_award_id_list_325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Tech 120"])},
    "company_award_id_list_383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Workplace"])}
  },
  "en": {
    
  }
}