export default {
  "fr": {
    "status_list_id_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À traiter"])},
    "status_list_id_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagés"])},
    "status_list_id_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intéressés"])},
    "status_list_id_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDV pris"])},
    "status_list_id_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans potentiel"])},
    "status_list_id_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimés"])}
  },
  "en": {
    
  }
}