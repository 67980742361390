export default {
  "fr": {
    "job_available_communication_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail direct"])},
    "job_available_communication_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne directe"])},
    "job_available_communication_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un numéro de téléphone"])},
    "job_available_communication_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées assistant(e)"])},
    "job_available_communication_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile"])},
    "main_job_available_communication_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail direct"])},
    "main_job_available_communication_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne directe"])},
    "main_job_available_communication_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un numéro de téléphone"])},
    "main_job_available_communication_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées assistant(e)"])},
    "main_job_available_communication_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile"])}
  },
  "en": {
    
  }
}