export default {
  "fr": {
    "task_type_id_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel"])},
    "task_type_id_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin"])},
    "task_type_id_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "task_type_id_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
  },
  "en": {
    
  }
}