export default {
  "fr": {
    "company_office_address_continent_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe"])},
    "company_office_address_continent_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amériques"])},
    "company_office_address_continent_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrique"])},
    "company_office_address_continent_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen Orient"])},
    "company_office_address_continent_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Océanie"])},
    "company_office_address_continent_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antilles"])},
    "company_office_address_continent_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie"])}
  },
  "en": {
    
  }
}