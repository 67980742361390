import {
  REGIONS_CONSTANTS,
} from '@/constants';

/**
 *
 * @param {object} campaign
 * @returns {boolean}
 */
export function removeDepartmentsIfRegionSelected(region_list, department_list) {
  let local_department_list = [...department_list];

  region_list.forEach((region_id) => {
    if (Object.prototype.hasOwnProperty.call(REGIONS_CONSTANTS, region_id)) {
      const department_id_list = REGIONS_CONSTANTS[region_id].departments
        .map((department_id) => parseInt(department_id, 10));

      local_department_list = local_department_list
        .filter((department_id) => !department_id_list.includes(department_id));
    }
  });

  return local_department_list;
}
