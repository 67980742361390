export default {
  "fr": {
    "company_business_opportunity_type_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissance externe"])},
    "company_business_opportunity_type_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement de l'activité"])},
    "company_business_opportunity_type_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau site"])},
    "company_business_opportunity_type_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversification"])},
    "company_business_opportunity_type_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet marketing"])},
    "company_business_opportunity_type_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement international"])},
    "company_business_opportunity_type_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet digital"])},
    "company_business_opportunity_type_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet IT"])},
    "company_business_opportunity_type_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement R&D"])},
    "company_business_opportunity_type_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises en difficulté"])},
    "company_business_opportunity_type_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutement et projet RH"])},
    "company_business_opportunity_type_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de fonds"])},
    "company_business_opportunity_type_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d'offres IT"])},
    "company_business_opportunity_type_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d'offres marketing"])},
    "company_business_opportunity_type_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d'offres formation"])}
  },
  "en": {
    
  }
}