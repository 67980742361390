export default {
  "fr": {
    "person_school_type_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["École d'ingénieur"])},
    "person_school_type_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["École de commerce"])},
    "person_school_type_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre grande école"])},
    "person_school_type_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Université"])},
    "person_school_type_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement d'enseignement supérieur"])},
    "person_school_type_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation continue"])},
    "person_school_type_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursus étranger"])}
  },
  "en": {
    
  }
}