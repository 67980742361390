import {
  QUERY_CONSTANTS,
} from '@/constants';

import {
  query_repository,
  search_repository,
} from '@/repositories';

const state = () => ({
  company_segment_list: [],
  criteria_organization_id_list: [],
  default_company_segment_list: [],
  query_list: [],
  query_list_light: [],
  saved_search_list: [],
});

const getters = {
  companySegmentList: (state) => state.company_segment_list,
  criteriaSequenceIdList: (state) => state.criteria_organization_id_list,
  defaultCompanySegmentList: (state) => state.default_company_segment_list,
  queryList: (state) => state.query_list,
  queryListLight: (state) => state.query_list_light,
  savedSearchList: (state) => state.saved_search_list,
};

const actions = {
  getCriteria({ state }) {
    return {
      organization_id_list: state.criteria_organization_id_list.length > 0
        ? state.criteria_organization_id_list
        : undefined,
    };
  },

  async processSearch({ commit, dispatch }, criteria) {
    //const criteria = await dispatch('getCriteria');
    const query_list = await dispatch('searchUserQueryList', criteria);

    commit('set_query_list', query_list.query_list);
  },

  async processSearchLight({ commit, dispatch }, criteria) {
    const query_list_light = await dispatch('searchUserQueryListLight', criteria);

    commit('set_query_list_light', query_list_light.query_list);
  },

  async searchUserQueryList({ rootState }, criteria) {
    const updated_criteria = {
      limit: 1000,
      offset: 0,
      sort: ['-creation_datetime'],
      ...criteria,
    };

    const result = await query_repository.searchUserQueryList(
      rootState.user.token_data.user_id,
      updated_criteria
    );

    return result;
  },

  async searchUserQueryListLight({ rootState }, criteria) {
    const updated_criteria = {
      limit: 1000,
      offset: 0,
      sort: ['-creation_datetime'],
      ...criteria,
    };

    const result = await query_repository.searchUserQueryListLight(
      rootState.user.token_data.user_id,
      updated_criteria
    );

    return result;
  },

  async searchSavedSearches({ commit, rootState, state }, criteria) {
    const updated_criteria = {
      query_type_id_list: [
        QUERY_CONSTANTS.TYPES.SEARCH,
      ],
      sort: ['-modification_date'],
      ...criteria,
    };

    const result = await query_repository.searchUserQueryList(
      rootState.user.token_data.user_id,
      updated_criteria
    );

    commit(
      'set_saved_search_list',
      (criteria.offset === 0)
        ? result.query_list
        : state.saved_search_list.concat(result.query_list)
    );

    return result;
  },

  async searchDefaultCompanySegments({ commit, rootState }) {
    const default_criteria = {
      query_type_id_list: [
        QUERY_CONSTANTS.TYPES.CLIENTS,
        QUERY_CONSTANTS.TYPES.PROSPECTS,
        QUERY_CONSTANTS.TYPES.COMPETITORS,
        QUERY_CONSTANTS.TYPES.KEY_ACCOUNTS,
      ],
    };

    const result = await query_repository.searchUserQueryList(
      rootState.user.token_data.user_id,
      default_criteria
    );

    await Promise.all(
      result.query_list.map((query) => search_repository
        .searchAccountActive({
          limit: 3,
          query_id_list: [query.id],
        })
        .then(({
          account_count,
          account_list,
        }) => {
          query.active_company_count = account_count;
          query.company_name_list = account_list.map((account) => account.name);
        }))
    );

    commit('set_default_company_segment_list', result.query_list);

    return result;
  },

  async searchCompanySegments({ commit, rootState, state }, criteria) {
    const updated_criteria = {
      query_type_id_list: [
        QUERY_CONSTANTS.TYPES.SEGMENT_ACCOUNT,
      ],
      sort: ['-modification_date'],
      ...criteria,
    };

    const result = await query_repository.searchUserQueryList(
      rootState.user.token_data.user_id,
      updated_criteria
    );

    await Promise.all(
      result.query_list.map((query) => search_repository
        .searchAccountActive({
          limit: 3,
          query_id_list: [query.id],
        })
        .then(({
          account_count,
          account_list,
        }) => {
          query.active_company_count = account_count;
          query.company_name_list = account_list.map((account) => account.name);
        }))
    );

    commit(
      'set_company_segment_list',
      (criteria.offset === 0)
        ? result.query_list
        : state.company_segment_list.concat(result.query_list)
    );

    return result;
  },

  async readQuery(_, query) {
    const result = await query_repository.readQuery(
      query.owner_user_id,
      query.query_id
    );

    return result;
  },

  async readQueryByIdList(_, query) {
    const result = await query_repository.readQueryByIdList(
      query.owner_user_id,
      query.query_id
    );

    return result.query_list;
  },

  createQuery(_, data) {
    return query_repository.createQuery({
      ...data,
      criteria: JSON.stringify(data.criteria),
    });
  },

  updateQuery(_, input) {
    const {
      query_id,
      data,
    } = input;

    return query_repository.updateQuery(
      query_id,
      {
        ...data,
        criteria: JSON.stringify(data.criteria),
      }
    );
  },

  deleteQuery(_, input) {
    const {
      query_id,
    } = input;

    return query_repository.deleteQuery(query_id);
  },
};

const mutations = {
  set_company_segment_list(state, company_segment_list) {
    state.company_segment_list = company_segment_list;
  },
  set_criteria_organization_id_list(state, criteria_organization_id_list) {
    state.criteria_organization_id_list = criteria_organization_id_list;
  },
  set_default_company_segment_list(state, default_company_segment_list) {
    state.default_company_segment_list = default_company_segment_list;
  },
  set_query_list(state, query_list) {
    state.query_list = query_list;
  },
  set_query_list_light(state, query_list_light) {
    state.query_list_light = query_list_light;
  },
  set_saved_search_list(state, saved_search_list) {
    state.saved_search_list = saved_search_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
