'use strict';

const TAG_MAPPING = {
  "company_id_list": "companyList",
  "company_active": "",
  "company_size_id_list": "companyProfileCriteriaSize",
  "company_structure_type_id_list": "companyProfileCriteria",
  "company_brand_name_list": "companyProfileBrand",
  "company_stock_market_index_id_list": "companyProfileCriteria",
  "company_publicly_traded": "companyProfileCriteria",
  "company_year_anniversary_list": "companyNews",
  "company_tag_id_list": "",
  "company_industry_type_id_list": "sectorCategory",
  "!company_industry_type_id_list": "sectorCategory",
  "company_industry_class_id_list": "sectorCategory",
  "company_workforce_range_lower_boundary_min": "companyProfileCriteria2",
  "company_workforce_range_lower_boundary_max": "companyProfileCriteria2",
  "company_workforce_range_upper_boundary_min": "companyProfileCriteria2",
  "company_workforce_range_upper_boundary_max": "companyProfileCriteria2",
  "company_sales_revenue_range_lower_boundary_min": "companyProfileCriteria2",
  "company_sales_revenue_range_lower_boundary_max": "companyProfileCriteria2",
  "company_sales_revenue_range_upper_boundary_min": "companyProfileCriteria2",
  "company_sales_revenue_range_upper_boundary_max": "companyProfileCriteria2",
  "corporation_siren_code_list": "identifiers",
  "advertiser_tag_id_list": "companyProfileCriteria",
  "advertiser_investment_total_amount_min": "companyProfileCriteria2",
  "advertiser_investment_total_amount_max": "companyProfileCriteria2",
  "advertiser_investment_media_type_id_list": "companyProfileCriteria2",
  "cac_start_date_lower_boundary": "cacStartDate",
  "cac_start_date_upper_boundary": "cacStartDate",
  "closure_date_lower_boundary": "closureDate",
  "closure_date_upper_boundary": "closureDate",
  "company_office_siret_code_list": "identifiers",
  "company_office_address_region_id_list": "localisations",
  "company_office_address_department_id_list": "localisations",
  "company_office_address_country_id_list": "localisations",
  "company_office_address_city_name_list": "localisations",
  "company_office_address_zip_code_list": "localisations",
  "company_phone_number_available": "",
  "company_award_id_list": "companyNews",
  "regional_leaders_id_list": "companyNews",
  "company_business_opportunity_type_id_list": "companyNews",
  "company_business_opportunity_publication_date_min": "companyNews",
  "company_business_opportunity_publication_date_max": "companyNews",
  "company_business_opportunity_publication_date_min_now_minus_month": "companyNews",
  "company_business_opportunity_publication_date_max_now": "companyNews",
  "company_activity_description_keyword_list": "sectorKeyword",
  "company_growth_id_list": "companyProfileCriteria2",
  "company_idcc_id_list": "sectorIDCC",
  "company_office_siret_naf_code_list": "sectorNaf",
  "company_name_id_list": "companyList",
  "is_group_head": "companyProfileCriteria",
  "is_group_head_with_their_subsidiaries": "companyProfileCriteria",
  "person_id_list": "personList",
  "person_school_id_list": "decisionMakerSchool",
  "person_school_type_id_list": "decisionMakerSchoolType",
  "person_hobby_id_list": "decisionMakerHobbies",
  "job_id_list": "identifiers",
  "main_job_function_id_list": "jobPosition",
  "job_function_id_list": "jobPosition",
  "job_type_id_list":  "jobPosition",
  "job_direct_email_available": "",
  "job_direct_phone_number_available": "",
  "job_assistant_phone_number_available": "",
  "job_assistant_first_name_available": "",
  "job_assistant_last_name_available": "",
  "job_assistant_email_available": "",
  "job_assistant_available": "",
  "job_phone_available": "",
  "job_available_communication_id_list": "decisionMakerCommunication",
  "job_previous_company_id_list": "decisionMakerPreviousJob",
  "job_previous_company_query_id_list": "decisionMakerPreviousJob",
  "job_news_publication_date_min": "decisionMakerNews",
  "job_news_publication_date_max": "decisionMakerNews",
  "job_news_publication_date_min_now_minus_month": "decisionMakerNews",
  "job_news_publication_date_max_now": "decisionMakerNews",
  "news_type_id_list": "decisionMakerNews",
  "news_type_id": "decisionMakerNews",
  "news_tag_id_list": "decisionMakerNews",
  "job_keyword_list": "jobPositionKeyword",
  "job_highlight_start_date_min": "decisionMakerNews",
  "job_highlight_start_date_min_now": "decisionMakerNews",
  "job_highlight_start_date_min_now_minus_month": "decisionMakerNews",
  "job_highlight_start_date_max": "decisionMakerNews",
  "job_highlight_start_date_max_now": "decisionMakerNews",
  "job_highlight_end_date_min": "decisionMakerNews",
  "job_highlight_end_date_max": "decisionMakerNews",
  "job_hierarchical_level_tag_id_list": "decisionMakerHierarchicalLevel",
  "press_id_list": "",
  "conference_id_list": "",
  "conference_speaker_id_list": "",
  "business_opportunity_id_list": "",
  "query_id_list": "includeList",
  "!query_id_list": "excludeList",
  "segment_id_list": "includeSegment",
  "!segment_id_list": "excludeSegment",
  "campaign_query_id": "includeCampaign",
  "!campaign_query_id": "excludeCampaign",
  "!email_domain_list": "identifiers",
  "!email_list": "identifiers",
  "group_head_country_list": "groupHeadCountryList",
  "group_head_country_tag_id_list": "groupHeadCountryTagIdList",
  "in_my_network": "inMyNetwork",
  "max_contact_per_company": "maxContactPerCompany",
  "sequence_query_id_list": "includeSequence",
  "!sequence_query_id_list": "excludeSequence",
};

export {
  TAG_MAPPING,
};
