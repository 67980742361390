export default {
  "fr": {
    "country_tag_id_list_111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Afrique du Sud"])},
    "country_tag_id_list_112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Allemagne"])},
    "country_tag_id_list_113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Australie"])},
    "country_tag_id_list_114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Autriche"])},
    "country_tag_id_list_115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aux Bahamas"])},
    "country_tag_id_list_116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Belgique"])},
    "country_tag_id_list_117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Brésil"])},
    "country_tag_id_list_118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Canada"])},
    "country_tag_id_list_119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Chine"])},
    "country_tag_id_list_120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Corée du Sud"])},
    "country_tag_id_list_121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Danemark"])},
    "country_tag_id_list_122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Espagne"])},
    "country_tag_id_list_123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aux Etats-Unis"])},
    "country_tag_id_list_124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Finlande"])},
    "country_tag_id_list_125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Royaume-Uni"])},
    "country_tag_id_list_126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Grèce"])},
    "country_tag_id_list_127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inde"])},
    "country_tag_id_list_128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Irlande"])},
    "country_tag_id_list_129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Islande"])},
    "country_tag_id_list_130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Italie"])},
    "country_tag_id_list_131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Japon"])},
    "country_tag_id_list_132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Luxembourg"])},
    "country_tag_id_list_133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Maroc"])},
    "country_tag_id_list_134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Norvège"])},
    "country_tag_id_list_135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aux Pays-Bas"])},
    "country_tag_id_list_136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au Qatar"])},
    "country_tag_id_list_137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à Singapour"])},
    "country_tag_id_list_138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Suède"])},
    "country_tag_id_list_140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à Taiwan"])},
    "country_tag_id_list_242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en Israël"])}
  },
  "en": {
    
  }
}