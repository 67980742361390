'use strict';

const REGIONS_CONSTANTS = {
  '101': {
    name: 'Auvergne-Rhône-Alpes',
    departments: [
      '03',
      '01',
      '07',
      '38',
      '15',
      '26',
      '73',
      '74',
      '69',
      '42',
      '43',
      '63',
    ],
  },
  '102': {
    name: 'Bourgogne-Franche-Comté',
    departments: [
      '89',
      '90',
      '39',
      '21',
      '71',
      '70',
      '25',
      '58',
    ],
  },
  '103': {
    name: 'Bretagne',
    departments: [
      '35',
      '22',
      '29',
      '56',
    ],
  },
  '104': {
    name: 'Centre-Val-de-Loire',
    departments: [
      '37',
      '36',
      '45',
      '28',
      '18',
      '41',
    ],
  },
  '105': {
    name: 'Grand Est',
    departments: [
      '88',
      '67',
      '68',
      '57',
      '08',
      '10',
      '51',
      '52',
      '54',
      '55',
    ],
  },
  '106': {
    name: 'Hauts-de-France',
    departments: [
      '02',
      '80',
      '62',
      '59',
      '60',
    ],
  },
  '107': {
    name: 'Île-de-France',
    departments: [
      '91',
      '92',
      '93',
      '94',
      '95',
      '78',
      '75',
      '77',
    ],
  },
  '108': {
    name: 'Normandie',
    departments: [
      '14',
      '27',
      '76',
      '50',
      '61',
    ],
  },
  '109': {
    name: 'Nouvelle-Aquitaine',
    departments: [
      '86',
      '87',
      '16',
      '79',
      '47',
      '23',
      '33',
      '24',
      '40',
      '17',
      '19',
      '64',
    ],
  },
  '110': {
    name: 'Occitanie',
    departments: [
      '81',
      '82',
      '11',
      '12',
      '34',
      '46',
      '48',
      '31',
      '32',
      '30',
      '09',
      '66',
      '65',
    ],
  },
  '111': {
    name: 'Pays de la Loire',
    departments: [
      '85',
      '72',
      '44',
      '49',
      '53',
    ],
  },
  '112': {
    name: 'PACA - Monaco - Corse',
    departments: [
      '04',
      '05',
      '06',
      '83',
      '84',
      '13',
      '20',
    ],
  },
};

export {
  REGIONS_CONSTANTS,
};
