import {
  query_repository,
  referential_repository,
  school_repository,
  search_repository,
} from '@/repositories';

import {
  QUERY_CONSTANTS,
} from '@/constants';

import {
  TAG_MAPPING,
  TAG_ORDER_MAPPING,
  TAG_PLURAL_MAPPING,
} from '@/constants/tags';

import i18n from '@/plugins/i18n';
import tags from '@/locales/tags.json';

const referentials = {};
const context = require.context('@/locales/referentials', false, /\.json$/);

context
  .keys()
  .forEach((key) => {
    const file_name = key
      .replace('./', '')
      .replace('.json', '');

    const file_content = context(key);

    referentials[file_name] = file_content.default || file_content;
  });

const tag_referentials = Object.values(referentials)
  .flatMap(Object.entries)
  .reduce((acc, [key, value]) => {
    acc[key] = { ...acc[key], ...value };

    return acc;
  }, {});

const tag_list = {};

Object
  .keys(tags)
  .forEach((key) => {
    tag_list[key] = {
      ...tags[key],
      ...tag_referentials[key],
    };
  });

import {
  removeDepartmentsIfRegionSelected,
} from '@/composables/location.js';

const getTag = (key, params = {}) => {
  const tag_context = {
    normalize: (strings) => strings.join(''),
    interpolate: (value) => value,
    named: (name) => params[name],
    plural: (count) => count || 0,
  };

  try {
    const tag = tag_list[i18n.global.locale][key](tag_context, params);

    if (Array.isArray(tag)) {
      if (tag.length === 2) {
        return (params.count > 1)
          ? tag[1]
          : tag[0];
      } else if (tag.length === 3) {
        if (params.count === 0) {
          return tag[0];
        } else if (params.count === 1) {
          return tag[1];
        } else {
          return tag[2];
        }
      } else {
        return tag[0];
      }
    }

    return tag;
  } catch (error) {
    return '';
  }
};

const state = () => ({
  cache_lists_and_segments: {
    query_id_list: [],
    ['!query_id_list']: [],
    segment_id_list: [],
    ['!segment_id_list']: [],
  },
  tag_group_list: [],
});

const getters = {};

const actions = {
  getTagGroupList({ commit, state }) {
    if (state.tag_group_list.length > 0) {
      return state.tag_group_list;
    }

    const tag_group_list = [];

    Object
      .keys(TAG_MAPPING)
      .forEach((key) => {
        if (
          tag_group_list.indexOf(TAG_MAPPING[key]) < 0
          && TAG_MAPPING[key] !== ''
        ) {
          tag_group_list.push(TAG_MAPPING[key]);
        }
      });

    commit('set_tag_group_list', tag_group_list);

    return tag_group_list;
  },
  async getMissingDataFromCriteria({ rootState }, input) {
    const {
      criteria,
    } = input;

    const new_criteria = { ...criteria };

    const user_id = rootState.user.token_data.user_id;

    let company_idcc_promise;
    let company_name_promise;
    let company_query_promise;
    let school_promise;

    if (criteria.company_idcc_id_list) {
      company_idcc_promise = referential_repository
        .getIDCC({
          id_list: criteria.company_idcc_id_list,
        })
        .then((company_idcc_list) => {
          new_criteria.company_idcc_id_list = company_idcc_list;
        })
        .catch(() => {
          new_criteria.company_idcc_id_list = [];
        });
    }

    if (criteria.job_previous_company_id_list) {
      company_name_promise = search_repository
        .searchAccount({
          field_list: ['id', 'name'],
          company_id_list: criteria.job_previous_company_id_list,
        })
        .then((company_name_response) => {
          new_criteria.job_previous_company_id_list = company_name_response.account_list
            .map((company) => ({
              id: company.id,
              text: company.name,
            }));
        })
        .catch(() => {
          new_criteria.job_previous_company_id_list = [];
        });
    }

    if (criteria.job_previous_company_query_id_list) {
      company_query_promise = query_repository
        .searchUserQueryList(
          user_id,
          {
            id_list: criteria.job_previous_company_query_id_list,
          }
        )
        .then((company_query_response) => {
          new_criteria.job_previous_company_query_id_list = company_query_response.query_list
            .map((query) => ({
              id: query.id,
              text: query.name,
            }));
        })
        .catch(() => {
          new_criteria.job_previous_company_query_id_list = [];
        });
    }

    if (criteria.person_school_id_list) {
      school_promise = school_repository
        .searchSchools({
          id_list: criteria.person_school_id_list,
        })
        .then((school_list) => {
          new_criteria.person_school_id_list = school_list
            .map((school) => ({
              id: school.id,
              text: school.name[0],
            }));
        })
        .catch(() => {
          new_criteria.person_school_id_list = [];
        });
    }

    await Promise.all([
      company_idcc_promise,
      company_name_promise,
      company_query_promise,
      school_promise,
    ]);

    return new_criteria;
  },
  async getTags({ commit, dispatch, rootState, state }, input) {
    const {
      criteria,
    } = input;

    const new_criteria = await dispatch(
      'getMissingDataFromCriteria',
      {
        criteria,
      }
    );

    const user_id = rootState.user.token_data.user_id;
    const promise_list = [];

    const skipped_tag_group_list = [
      'company_industry_class_id_list',
      'job_type_id_list',
      'past_conference',
      'upcoming_conference',
      'previous_job_switcher',
      'schools_switcher',
      'school_option_switcher',
      'business_alerts_slider_value',
      'conference_slider_value',
      'future_events_checkbox',
      'sort',
      'effective_slider_value_min',
      'effective_slider_value_max',
      'turnover_slider_value_min',
      'turnover_slider_value_max',
      'investing_amount_slider_value_min',
      'investing_amount_slider_value_max',
      'business_opportunity_slider_value',
      'company_business_opportunity_publication_date_min_now_minus_month',
      'company_business_opportunity_publication_date_max_now',
    ];

    const formatListsAndSegments = (list_name, segment_name) => {
      if (
        new_criteria[list_name]
        && Array.isArray(new_criteria[list_name])
        && new_criteria[list_name].length > 0
      ) {
        const promise = query_repository
          .searchUserQueryList(
            user_id,
            {
              id_list: new_criteria[list_name],
            }
          )
          .then((response) => {
            const lists = response.query_list;
            const query_id_list = [];
            const segment_id_list = [];

            lists.forEach((list) => {
              if (
                [
                  QUERY_CONSTANTS.TYPES.SEGMENT_CONTACT,
                  QUERY_CONSTANTS.TYPES.SEGMENT_ACCOUNT,
                  QUERY_CONSTANTS.TYPES.ADDRESS_BOOK,
                  QUERY_CONSTANTS.TYPES.CLIENTS,
                  QUERY_CONSTANTS.TYPES.PROSPECTS,
                  QUERY_CONSTANTS.TYPES.COMPETITORS,
                  QUERY_CONSTANTS.TYPES.KEY_ACCOUNTS,
                ].includes(list.query_type.id)
              ) {
                segment_id_list.push(list.id);
              } else {
                query_id_list.push(list.id);
              }
            });

            const cache_lists_and_segments = { ...state.cache_lists_and_segments };

            cache_lists_and_segments[list_name] = query_id_list;
            cache_lists_and_segments[segment_name] = segment_id_list;

            commit('set_cache_lists_and_segments', cache_lists_and_segments);

            new_criteria[list_name] = query_id_list;
            new_criteria[segment_name] = segment_id_list;
          });

        promise_list.push(promise);
      }
    };

    const formatInMyNetwork = () => {
      if (
        new_criteria.query_type_id_list
        && new_criteria.query_type_id_list.includes(QUERY_CONSTANTS.TYPES.NETWORK_SCHOOL_CONTACT)
        && new_criteria.query_type_id_list.includes(QUERY_CONSTANTS.TYPES.NETWORK_COMPANY_CONTACT)
      ) {
        new_criteria.in_my_network = true;
      }

      if (new_criteria.in_my_network === false) {
        delete new_criteria.in_my_network;
      }

      delete new_criteria.query_type_id_list;
    };

    const formatDepartmentsAndRegions = () => {
      if (
        Array.isArray(new_criteria.company_office_address_department_id_list)
        && Array.isArray(new_criteria.company_office_address_region_id_list)
      ) {
        new_criteria.company_office_address_department_id_list = removeDepartmentsIfRegionSelected(
          new_criteria.company_office_address_region_id_list,
          new_criteria.company_office_address_department_id_list
        );
      }
    };

    const formatToUndefined = () => {
      if (
        new_criteria.company_workforce_range_upper_boundary_max
        && new_criteria.company_workforce_range_lower_boundary_min
      ) {
        new_criteria.company_workforce_range_lower_boundary_min = undefined;
      }

      if (
        new_criteria.company_sales_revenue_range_upper_boundary_max
        && new_criteria.company_sales_revenue_range_lower_boundary_min
      ) {
        new_criteria.company_sales_revenue_range_lower_boundary_min = undefined;
      }

      if (
        new_criteria.advertiser_investment_total_amount_max
        && new_criteria.advertiser_investment_total_amount_min
      ) {
        new_criteria.advertiser_investment_total_amount_min = undefined;
      }

      if (
        'job_highlight_start_date_min' in new_criteria
        && 'job_highlight_start_date_max' in new_criteria
      ) {
        new_criteria.job_highlight_start_date_max = undefined;
      }

      if (
        'job_news_publication_date_min' in new_criteria
        && 'job_news_publication_date_max' in new_criteria
      ) {
        new_criteria.job_news_publication_date_min = undefined;
      }

      if ('job_news_publication_date_min_now_minus_month' in new_criteria) {
        new_criteria.job_news_publication_date_min_now_minus_month = undefined;
      }

      if ('job_news_publication_date_max_now' in new_criteria) {
        new_criteria.job_news_publication_date_max_now = undefined;
      }

      if (
        'job_highlight_start_date_max_now' in new_criteria
        && new_criteria.job_highlight_start_date_max_now !== undefined
        && 'job_highlight_start_date_min_now_minus_month' in new_criteria
      ) {
        new_criteria.job_highlight_start_date_min_now_minus_month = undefined;
      }

      if (
        'job_highlight_start_date_max_now' in new_criteria
        && new_criteria.job_highlight_start_date_max_now !== undefined
        && 'job_highlight_start_date_min_now' in new_criteria
      ) {
        new_criteria.job_highlight_start_date_min_now = undefined;
      }

      if (new_criteria.is_headquarters === false) {
        new_criteria.is_headquarters = undefined;
      }

      if (new_criteria.is_group_head === false) {
        new_criteria.is_group_head = undefined;
      }

      if (new_criteria.is_group_head_with_their_subsidiaries === false) {
        new_criteria.is_group_head_with_their_subsidiaries = undefined;
      }

      if (new_criteria.person_experience_completed === false) {
        new_criteria.person_experience_completed = undefined;
      }

      if (new_criteria.person_studies_completed === false) {
        new_criteria.person_studies_completed = undefined;
      }
    };

    formatListsAndSegments('query_id_list', 'segment_id_list');
    formatListsAndSegments('!query_id_list', '!segment_id_list');

    await Promise.all(promise_list);

    formatInMyNetwork();
    formatDepartmentsAndRegions();
    formatToUndefined();

    let tag_data_list = [];
    let tag_label;

    Object.entries(new_criteria).forEach(([key, value]) => {
      let color;

      if (skipped_tag_group_list.indexOf(key) >= 0) {
        return;
      }

      if (
        value !== undefined
        && value !== ''
        && !Array.isArray(value)
      ) {
        if (TAG_MAPPING[key] === 'companyProfileCriteria2') {
          tag_label = getTag('one_company_criterion');
        } else if (TAG_MAPPING[key] === 'closureDate') {
          tag_label = getTag('closure_date');
        } else if (TAG_MAPPING[key] === 'cacStartDate') {
          tag_label = getTag('cac_start_date');
        } else if (key === 'company_publicly_traded') {
          if (value === false) {
            tag_label = getTag('unclassified_company');
          } else {
            tag_label = undefined;
          }
        } else if (
          key === 'job_highlight_start_date_min'
          || key === 'job_highlight_start_date_max'
          || key === 'job_highlight_start_date_min_now'
          || key === 'job_highlight_start_date_min_now_minus_month'
          || key === 'job_highlight_start_date_max_now'
        ) {
          tag_label = getTag('speaker');
        } else if (key === 'is_headquarters') {
          tag_label = getTag('headquarters_only');
        } else if (key === 'is_group_head') {
          tag_label = getTag('group_heads_only');
        } else if (key === 'is_group_head_with_their_subsidiaries') {
          tag_label = getTag('group_heads_and_their_subsidiaries');
        } else if (key === 'person_experience_completed') {
          tag_label = getTag('completed_experience');
        } else if (key === 'person_studies_completed') {
          tag_label = getTag('completed_formation');
        } else if (key === 'in_my_network') {
          tag_label = getTag('in_my_network');
        } else if (key === 'max_contact_per_company') {
          tag_label = getTag(
            'max_contact_per_company',
            {
              count: value,
            }
          );
        } else {
          tag_label = value;
        }

        if (typeof tag_label !== 'undefined') {
          tag_data_list.push({
            label: tag_label,
            group: TAG_MAPPING[key],
          });
        }
      } else if (value !== undefined) {
        value.forEach((sub_value) => {
          if (
            skipped_tag_group_list.indexOf(key) !== -1
            || sub_value === false
          ) {
            return;
          }

          if (isNaN(sub_value)) {
            if (key === 'job_previous_company_id_list') {
              tag_label = `Ex-${value.text}`;
            } else if (key === 'job_previous_company_query_id_list') {
              tag_label = `Ex-${value.text}`;
            } else if (key === 'person_school_id_list') {
              tag_label = `${Array.isArray(value) ? value[0].text : value.text}`;
            } else if (key === 'company_idcc_id_list') {
              tag_label = `${value.name}`;
            } else {
              tag_label = value;
            }
          } else if (key === 'news_type_id_list') {
            tag_label = getTag(`news_tag_id_list_${sub_value}`);
          } else if (key === '!company_industry_type_id_list') {
            tag_label = getTag(`company_industry_type_id_list_${sub_value}`);
            color = 'warning';
          } else if (
            key === 'job_highlight_start_date_min'
            || key === 'job_highlight_start_date_max'
            || key === 'job_highlight_start_date_min_now'
            || key === 'job_highlight_start_date_min_now_minus_month'
            || key === 'job_highlight_start_date_max_now'
          ) {
            tag_label = getTag('speaker');
          } else if (key === 'person_hobby_id_list') {
            tag_label = getTag('one_hobby');
          } else if (key === 'company_year_anniversary_list') {
            tag_label = value;
          } else if (key === 'query_id_list') {
            tag_label = getTag('one_list_included');
          } else if (key === '!query_id_list') {
            tag_label = getTag('one_list_excluded');
          } else if (key === 'campaign_query_id') {
            tag_label = getTag('one_campaign_included');
          } else if (key === '!campaign_query_id') {
            tag_label = getTag('one_campaign_excluded');
          } else if (key === 'sequence_query_id_list') {
            tag_label = getTag('one_sequence_included');
          } else if (key === '!sequence_query_id_list') {
            tag_label = getTag('one_sequence_excluded');
          } else if (key === 'segment_id_list') {
            tag_label = getTag('one_segment_included');
          } else if (key === '!segment_id_list') {
            tag_label = getTag('one_segment_excluded');
          } else if (
            key === 'person_id_list'
            || key === 'job_id_list'
            || key === 'company_id_list'
            || key === 'corporation_siren_code_list'
            || key === 'company_office_siret_code_list'
            || key === '!email_domain_list'
            || key === '!email_list'
          ) {
            tag_label = getTag('one_identifier');
          } else if (key === 'company_office_address_zip_code_list') {
            tag_label = value;
          } else if (key === 'company_name_id_list') {
            tag_label = getTag('one_company');
          } else if (key === 'cac_company_id_list') {
            tag_label = getTag('one_cac_company');
          } else if (key === 'group_head_country_list') {
            tag_label = `${getTag('group_head_in')} ${getTag(`company_office_address_country_id_list_${sub_value}`)}`;
          } else if (key === 'group_head_country_tag_id_list') {
            tag_label = `${getTag('group_head_in')} ${getTag(`country_tag_id_list_${sub_value}`)}`;
          } else if (key === 'main_job_function_id_list') {
            tag_label = `${getTag(`job_function_id_list_${sub_value}`)}`;
          } else {
            tag_label = getTag(`${key}_${sub_value}`);
          }

          const group = TAG_MAPPING[key];
          // const group = (
          //   key === 'company_size_id_list'
          //   && $deps.$state.current.name !== 'app.personalSpaceHome.personalSpaceTabAlertList.details'
          // )
          //   ? TAG_MAPPING.company_structure_type_id_list
          //   : TAG_MAPPING[key];

          tag_data_list.push({
            color,
            group,
            label: tag_label,
          });
        });
      }
    });

    tag_data_list = await dispatch('groupTags', tag_data_list);
    tag_data_list = await dispatch('orderTags', tag_data_list);

    return tag_data_list;
  },
  async groupTags({ dispatch }, tag_list) {
    let new_tag_list = [...tag_list];
    let element_count = 0;
    let tag_label = '';

    const tag_group_list = await dispatch('getTagGroupList');

    tag_group_list.forEach((tag_group) => {
      element_count = new_tag_list
        .filter((tag) => tag.group === tag_group)
        .length;

      if (element_count > 1) {
        new_tag_list = new_tag_list.filter((tag) => tag.group !== tag_group);

        if (
          tag_group === 'decisionMakerCommunication'
          || tag_group === 'closureDate'
          || tag_group === 'cacStartDate'
        ) {
          tag_label = `${TAG_PLURAL_MAPPING[tag_group]}`;
        } else if (
          tag_group === 'includeList'
          || tag_group === 'includeSegment'
          || tag_group === 'includeCampaign'
          || tag_group === 'includeSequence'
          || tag_group === 'groupHeadCountryList'
          || tag_group === 'groupHeadCountryTagIdList'
        ) {
          tag_label = `${TAG_PLURAL_MAPPING[tag_group]}`;
          tag_label = tag_label.replace('??', element_count);
        } else {
          tag_label = `${element_count} ${TAG_PLURAL_MAPPING[tag_group]}`;
        }

        new_tag_list.push({
          group: tag_group,
          label: tag_label,
        });
      }
    });

    return new_tag_list;
  },
  async orderTags(_, tag_list) {
    return tag_list.sort((tag_1, tag_2) => {
      if (TAG_ORDER_MAPPING[tag_1.group] < TAG_ORDER_MAPPING[tag_2.group]) {
        return -1;
      }

      if (TAG_ORDER_MAPPING[tag_1.group] > TAG_ORDER_MAPPING[tag_2.group]) {
        return 1;
      }

      return 0;
    });
  },
};

const mutations = {
  set_cache_lists_and_segments(state, cache_lists_and_segments) {
    state.cache_lists_and_segments = cache_lists_and_segments;
  },
  set_tag_group_list(state, tag_group_list) {
    state.tag_group_list = tag_group_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
