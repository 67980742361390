export default {
  "fr": {
    "company_industry_class_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration & collectivités"])},
    "company_industry_class_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque, Finance"])},
    "company_industry_class_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biens de consommation, Luxe"])},
    "company_industry_class_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTP, Immobilier"])},
    "company_industry_class_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil"])},
    "company_industry_class_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution"])},
    "company_industry_class_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-tech"])},
    "company_industry_class_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industries"])},
    "company_industry_class_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médias, Entertainment"])},
    "company_industry_class_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur associatif"])},
    "company_industry_class_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "company_industry_class_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports, logistique"])}
  },
  "en": {
    
  }
}