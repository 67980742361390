export default {
  "fr": {
    "job_class_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gouvernance"])},
    "job_class_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction générale"])},
    "job_class_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctions supports"])},
    "job_class_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing, commercial, communication"])},
    "job_class_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technique"])},
    "job_class_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métiers spécifiques"])},
    "job_class_id_list_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres fonctions"])}
  },
  "en": {
    
  }
}