export default {
  "fr": {
    "job_hierarchical_level_tag_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur"])},
    "job_hierarchical_level_tag_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable"])},
    "job_hierarchical_level_tag_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codir / Comex"])},
    "job_hierarchical_level_tag_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C-Level"])},
    "job_hierarchical_level_tag_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérationnel / Expert"])}
  },
  "en": {
    
  }
}