export default {
  "fr": {
    "company_office_address_country_id_list_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "company_office_address_country_id_list_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrique du Sud"])},
    "company_office_address_country_id_list_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanie"])},
    "company_office_address_country_id_list_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algérie"])},
    "company_office_address_country_id_list_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemagne"])},
    "company_office_address_country_id_list_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorre"])},
    "company_office_address_country_id_list_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Royaume-Uni"])},
    "company_office_address_country_id_list_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "company_office_address_country_id_list_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "company_office_address_country_id_list_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabie Saoudite"])},
    "company_office_address_country_id_list_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentine"])},
    "company_office_address_country_id_list_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arménie"])},
    "company_office_address_country_id_list_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "company_office_address_country_id_list_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australie"])},
    "company_office_address_country_id_list_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autriche"])},
    "company_office_address_country_id_list_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaidjan"])},
    "company_office_address_country_id_list_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "company_office_address_country_id_list_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrein"])},
    "company_office_address_country_id_list_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "company_office_address_country_id_list_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbade"])},
    "company_office_address_country_id_list_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])},
    "company_office_address_country_id_list_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "company_office_address_country_id_list_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "company_office_address_country_id_list_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudes"])},
    "company_office_address_country_id_list_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhoutan"])},
    "company_office_address_country_id_list_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bielorussie"])},
    "company_office_address_country_id_list_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birmanie"])},
    "company_office_address_country_id_list_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivie"])},
    "company_office_address_country_id_list_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnie Herzégovine"])},
    "company_office_address_country_id_list_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "company_office_address_country_id_list_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brésil"])},
    "company_office_address_country_id_list_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "company_office_address_country_id_list_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarie"])},
    "company_office_address_country_id_list_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "company_office_address_country_id_list_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "company_office_address_country_id_list_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caïman (Iles)"])},
    "company_office_address_country_id_list_37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodge"])},
    "company_office_address_country_id_list_38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroun"])},
    "company_office_address_country_id_list_39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "company_office_address_country_id_list_40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cap Vert"])},
    "company_office_address_country_id_list_41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrafricaine, République"])},
    "company_office_address_country_id_list_42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chili"])},
    "company_office_address_country_id_list_43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chine"])},
    "company_office_address_country_id_list_44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chypre"])},
    "company_office_address_country_id_list_45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombie"])},
    "company_office_address_country_id_list_46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comores"])},
    "company_office_address_country_id_list_47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "company_office_address_country_id_list_48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo, République Democratique"])},
    "company_office_address_country_id_list_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooks (Iles)"])},
    "company_office_address_country_id_list_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corée du Nord"])},
    "company_office_address_country_id_list_51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corée du Sud"])},
    "company_office_address_country_id_list_52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "company_office_address_country_id_list_53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d'Ivoire"])},
    "company_office_address_country_id_list_54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatie"])},
    "company_office_address_country_id_list_55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "company_office_address_country_id_list_56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danemark"])},
    "company_office_address_country_id_list_57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "company_office_address_country_id_list_58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominicaine, République"])},
    "company_office_address_country_id_list_59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominique"])},
    "company_office_address_country_id_list_60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypte"])},
    "company_office_address_country_id_list_61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "company_office_address_country_id_list_62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émirats Arabes Unis"])},
    "company_office_address_country_id_list_63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equateur"])},
    "company_office_address_country_id_list_64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erythrée"])},
    "company_office_address_country_id_list_65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagne"])},
    "company_office_address_country_id_list_66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonie"])},
    "company_office_address_country_id_list_67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etats-Unis"])},
    "company_office_address_country_id_list_68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopie"])},
    "company_office_address_country_id_list_69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russie"])},
    "company_office_address_country_id_list_70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fidji"])},
    "company_office_address_country_id_list_71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlande"])},
    "company_office_address_country_id_list_72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "company_office_address_country_id_list_73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "company_office_address_country_id_list_74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambie"])},
    "company_office_address_country_id_list_75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géorgie"])},
    "company_office_address_country_id_list_76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "company_office_address_country_id_list_77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "company_office_address_country_id_list_78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grèce"])},
    "company_office_address_country_id_list_79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenade"])},
    "company_office_address_country_id_list_80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groenland"])},
    "company_office_address_country_id_list_81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "company_office_address_country_id_list_82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée"])},
    "company_office_address_country_id_list_83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée-Bissau"])},
    "company_office_address_country_id_list_84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée équatoriale"])},
    "company_office_address_country_id_list_85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "company_office_address_country_id_list_86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haïti"])},
    "company_office_address_country_id_list_87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "company_office_address_country_id_list_88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "company_office_address_country_id_list_89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrie"])},
    "company_office_address_country_id_list_90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iles Feroe"])},
    "company_office_address_country_id_list_91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inde"])},
    "company_office_address_country_id_list_92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésie"])},
    "company_office_address_country_id_list_93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "company_office_address_country_id_list_94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "company_office_address_country_id_list_95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlande"])},
    "company_office_address_country_id_list_96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islande"])},
    "company_office_address_country_id_list_97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israël"])},
    "company_office_address_country_id_list_98": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italie"])},
    "company_office_address_country_id_list_99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaïque"])},
    "company_office_address_country_id_list_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japon"])},
    "company_office_address_country_id_list_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordanie"])},
    "company_office_address_country_id_list_102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "company_office_address_country_id_list_103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "company_office_address_country_id_list_104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirghistan"])},
    "company_office_address_country_id_list_105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "company_office_address_country_id_list_106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koweït"])},
    "company_office_address_country_id_list_107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "company_office_address_country_id_list_108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "company_office_address_country_id_list_109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettonie"])},
    "company_office_address_country_id_list_110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liban"])},
    "company_office_address_country_id_list_111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "company_office_address_country_id_list_112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libye"])},
    "company_office_address_country_id_list_113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "company_office_address_country_id_list_114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituanie"])},
    "company_office_address_country_id_list_115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "company_office_address_country_id_list_116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macao"])},
    "company_office_address_country_id_list_117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macédoine"])},
    "company_office_address_country_id_list_118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "company_office_address_country_id_list_119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaisie"])},
    "company_office_address_country_id_list_120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "company_office_address_country_id_list_121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "company_office_address_country_id_list_122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "company_office_address_country_id_list_123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malte"])},
    "company_office_address_country_id_list_124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroc"])},
    "company_office_address_country_id_list_125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maurice"])},
    "company_office_address_country_id_list_126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritanie"])},
    "company_office_address_country_id_list_127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexique"])},
    "company_office_address_country_id_list_128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronésie"])},
    "company_office_address_country_id_list_129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavie"])},
    "company_office_address_country_id_list_130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "company_office_address_country_id_list_131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolie"])},
    "company_office_address_country_id_list_132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "company_office_address_country_id_list_133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "company_office_address_country_id_list_134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibie"])},
    "company_office_address_country_id_list_135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "company_office_address_country_id_list_136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Népal"])},
    "company_office_address_country_id_list_137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "company_office_address_country_id_list_138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "company_office_address_country_id_list_139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "company_office_address_country_id_list_140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvège"])},
    "company_office_address_country_id_list_141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Zélande"])},
    "company_office_address_country_id_list_142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "company_office_address_country_id_list_143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouganda"])},
    "company_office_address_country_id_list_144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouzbékistan"])},
    "company_office_address_country_id_list_145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "company_office_address_country_id_list_146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "company_office_address_country_id_list_147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papouasie-Nouvelle-Guinée"])},
    "company_office_address_country_id_list_148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "company_office_address_country_id_list_149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays-Bas"])},
    "company_office_address_country_id_list_150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pérou"])},
    "company_office_address_country_id_list_151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "company_office_address_country_id_list_152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pologne"])},
    "company_office_address_country_id_list_153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "company_office_address_country_id_list_154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "company_office_address_country_id_list_155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roumanie"])},
    "company_office_address_country_id_list_156": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "company_office_address_country_id_list_157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahara Occidental"])},
    "company_office_address_country_id_list_158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sainte-Hélène"])},
    "company_office_address_country_id_list_159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sainte-Lucie"])},
    "company_office_address_country_id_list_160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Marin"])},
    "company_office_address_country_id_list_161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Vincent"])},
    "company_office_address_country_id_list_162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salomon, Iles"])},
    "company_office_address_country_id_list_163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "company_office_address_country_id_list_164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tomé et Principe"])},
    "company_office_address_country_id_list_165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sénégal"])},
    "company_office_address_country_id_list_166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "company_office_address_country_id_list_167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "company_office_address_country_id_list_168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapour"])},
    "company_office_address_country_id_list_169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovaquie"])},
    "company_office_address_country_id_list_170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovénie"])},
    "company_office_address_country_id_list_171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalie"])},
    "company_office_address_country_id_list_172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudan"])},
    "company_office_address_country_id_list_173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "company_office_address_country_id_list_174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suède"])},
    "company_office_address_country_id_list_175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suisse"])},
    "company_office_address_country_id_list_176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "company_office_address_country_id_list_177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "company_office_address_country_id_list_178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syrie"])},
    "company_office_address_country_id_list_179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadjikistan"])},
    "company_office_address_country_id_list_180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzanie"])},
    "company_office_address_country_id_list_181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchad"])},
    "company_office_address_country_id_list_182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République Tchèque"])},
    "company_office_address_country_id_list_183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thaïlande"])},
    "company_office_address_country_id_list_184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor Oriental"])},
    "company_office_address_country_id_list_185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "company_office_address_country_id_list_186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "company_office_address_country_id_list_187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinité et Tobago"])},
    "company_office_address_country_id_list_188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisie"])},
    "company_office_address_country_id_list_189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "company_office_address_country_id_list_190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquie"])},
    "company_office_address_country_id_list_191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "company_office_address_country_id_list_192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "company_office_address_country_id_list_193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "company_office_address_country_id_list_194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "company_office_address_country_id_list_195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "company_office_address_country_id_list_196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viêt-nam"])},
    "company_office_address_country_id_list_197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "company_office_address_country_id_list_198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yougoslavie"])},
    "company_office_address_country_id_list_199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambie"])},
    "company_office_address_country_id_list_200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
    "company_office_address_country_id_list_202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taïwan"])}
  },
  "en": {
    
  }
}